import { createSlice } from "@reduxjs/toolkit";

const statusSlice = createSlice({
  name: "status",
  initialState: { message: "Ready" }, 
  reducers: {
    setStatusMessage: (state, action) => {
      state.message = action.payload; 
    },
    clearStatusMessage: (state) => {
      state.message = "Ready"; 
    },
  },
});


export const { setStatusMessage, clearStatusMessage } = statusSlice.actions;


export const selectStatusMessage = (state) => state.status.message;


export default statusSlice.reducer;

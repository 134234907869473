const XpraWindow = () => {
 
    const url = 'http://35.154.96.160:9010'; // Replace with your desired URL

    // Get the width and height of the user's screen
    const width = window.screen.width;
    const height = window.screen.height;

    // Define window features for full-screen window
    const features = `
      width=${width},
      height=${height},
      top=0,
      left=0,
      menubar=no,
      toolbar=no,
      location=no,
      status=no,
      resizable=yes
    `.replace(/\s+/g, ''); // Remove extra spaces

    // Open the new window
    const newWindow = window.open(url, 'fullScreenWindow', features);

    // Optional: Check if the window was blocked
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      console.log('Popup blocked or failed to open.');
    }

    // Cleanup function if you want to close the window when the component unmounts
    return () => {
      if (newWindow) {
        newWindow.close();
      }
    };

};

export default XpraWindow;

import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode === "true") return true;
    if (savedDarkMode === "false") return false;
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  });

  const [selectedMode, setSelectedMode] = useState(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    return savedDarkMode === "true" ? "dark" : savedDarkMode === "false" ? "light" : "system";
  });

  useEffect(() => {
    // Set darkMode based on localStorage or system preference
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    
    const handleChange = () => {
      if (selectedMode === "system") {
        setDarkMode(mediaQuery.matches);
      }
    };

    // Initial check to set darkMode on load
    handleChange();

    mediaQuery.addEventListener("change", handleChange);
    
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, [selectedMode]);

  useEffect(() => {
    // Apply the theme based on darkMode
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    if (selectedMode === "system") {
      localStorage.removeItem("darkMode");
    } else {
      localStorage.setItem("darkMode", darkMode.toString());
    }
  }, [darkMode, selectedMode]);

  const handleLightMode = () => {
    setDarkMode(false);
    setSelectedMode("light");
  };

  const handleDarkMode = () => {
    setDarkMode(true);
    setSelectedMode("dark");
  };

  const handleSystemDefault = () => {
    const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    setDarkMode(isDark);
    setSelectedMode("system");
  };

  return (
    <ThemeContext.Provider value={{ darkMode, selectedMode, handleLightMode, handleDarkMode, handleSystemDefault }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};

// Button Component
export const Button = ({ children, className, ...props }) => (
  <button
    className={`px-2 py-1 rounded-md transition-colors bg-primary text-text hover:bg-secondary dark:bg-primary dark:text-text dark:hover:bg-secondary ${className}`}
    {...props}
  >
    {children}
  </button>
);

// Select Component
export const Select = ({ children, ...props }) => (
  <select
    className="bg-background border border-gray-300 rounded-md px-2 py-1 text-sm text-text dark:bg-background dark:text-text"
    {...props}
  >
    {children}
  </select>
);

// Input Component
export const Input = ({ className, ...props }) => (
  <input
    className={`border border-gray-300 rounded-md px-2 py-1 text-sm text-text bg-background dark:bg-background dark:text-text ${className}`}
    {...props}
  />
);

// Buttons Component
export const Buttons = ({ children, onClick, className = "" }) => (
  <button
    className={`px-4 py-2 bg-primary text-text rounded hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 dark:bg-primary dark:text-text dark:hover:bg-secondary ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

import { createSlice } from "@reduxjs/toolkit";

const toggleExamplesSlice = createSlice({
  name: "toggleExamples",
  initialState: {
    isExamplesOpen: false,
  },
  reducers: {
    openExamples: (state) => {
      state.isExamplesOpen = true;
    },
    closeExamples: (state) => {
      state.isExamplesOpen = false;
    },
    toggleExamples: (state) => {
      state.isExamplesOpen = !state.isExamplesOpen;
    },
  },
});

export const { openExamples, closeExamples, toggleExamples } =
  toggleExamplesSlice.actions;

export const selectIsExamplesOpen = (state) => state.toggleExamples.isExamplesOpen;

export default toggleExamplesSlice.reducer;

import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import useDebouncedResizeObserver from "./DebouncedResizeObserver";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSettings,
  selectIsSettingsOpen,
  toggleSettings,
} from "../features/toggling/settingsSlice";
import { closeFileManager } from "../features/toggling/fileManagerSlice";
import { Button, Select } from "./UIcomponents";
import ToggleTheme from "./ToggleTheme";
import { PiFileLock } from "react-icons/pi";
import {
  selectShowExtraInfo,
  toggleExtraInfo,
} from "../features/showExtraInfoSlice";

const Settings = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsSettingsOpen);
  const showExtraInfo = useSelector(selectShowExtraInfo);
  const settingsRef = useRef(null);
  const resizeObserver = useDebouncedResizeObserver((entries) => {
    // Handle resize here if needed
  });

  useEffect(() => {
    if (settingsRef.current && resizeObserver) {
      resizeObserver.observe(settingsRef.current);
    }

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        dispatch(toggleSettings());
        dispatch(closeFileManager());
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [resizeObserver, dispatch, isOpen]);

  if (!isOpen) return null; // Do not render if the modal is closed

  return (
    <>
      <motion.div
        ref={settingsRef}
        initial={{ width: 0 }}
        animate={{ width: isOpen ? "20%" : 0 }}
        transition={{ duration: 0.5, ease: [0.42, 0, 0.58, 1] }}
        className="h-full w-full bg-background border-y-2 border-l border-primary z-5 shadow-xl overflow-hidden dark:bg-background dark:border-primary"
      >
        {/* Header */}
        <div className="bg-secondary flex border-b border-primary items-center justify-between p-2 dark:bg-secondary dark:border-primary">
          <h2 className="text-md font-semibold text-text">Settings</h2>
          <button
            onClick={() => dispatch(closeSettings())}
            className="p-1 hover:bg-accent rounded-full transition-colors dark:hover:bg-accent"
          >
            <FaTimes className="w-4 h-4 text-text dark:text-text" />
          </button>
        </div>

        {/* Content */}
        <div className="p-4 flex flex-col text-text dark:text-text justify-around gap-4">
          <div className="flex flex-col gap-4">
            <div className="w-full flex items-center justify-between">
              <label
                htmlFor="editorType"
                className=" text-text"
              >
                Editor
              </label>
              <span className="relative">
                <Select
                  id="editorType"
                  name="editorType"
                  required
                  className="block w-full rounded-md border border-gray-300 bg-gray-50 dark:bg-background px-3 py-1.5 text-sm text-text dark:text-text shadow-sm focus:border-primary dark:focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary dark:focus:ring-primary transition-colors duration-200 ease-in-out"
                >
                  <option value="Default">Default</option>
                  <option value="Vim">Vim</option>
                </Select>
              </span>
            </div>

            {/* Theme Toggle */}
            <div className="flex items-center justify-between">
              <div>Theme</div>
              <div>
                <ToggleTheme />
              </div>
            </div>
            <div className="flex items-start justify-between">
              <div>
                <label className="block leading-tight">
                  Show Extra Info in File Manager
                  <br />
                  <span className="text-xs text-gray-500">
                    (owner, group, permissions)
                  </span>
                </label>
              </div>
              <input
                type="checkbox"
                checked={showExtraInfo}
                onChange={() => dispatch(toggleExtraInfo())}
                className="ml-2 mt-1"
              />
            </div>

            {/* Other Settings Options */}
            <div>Settings....</div>
          </div>

          {/* Update Button */}
          {/* <div className="self-end">
            <Button className="rounded-md border-gray-300 bg-gray-400 dark:bg-background px-3 py-2 text-sm shadow-sm focus:border-primary dark:focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary dark:focus:ring-primary text-text dark:text-text">
              Update
            </Button>
          </div> */}
        </div>
      </motion.div>
    </>
  );
};

export default Settings;

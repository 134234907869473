import { createSlice } from "@reduxjs/toolkit";

// Function to load the initial state from localStorage
const loadHiddenFilesStateFromLocalStorage = () => {
  const savedState = localStorage.getItem("showHiddenFiles");
  return savedState ? JSON.parse(savedState) : false;
};

// Function to save state to localStorage
const saveHiddenFilesStateToLocalStorage = (state) => {
  localStorage.setItem("showHiddenFiles", JSON.stringify(state));
};

const hiddenFilesSlice = createSlice({
  name: "hiddenFiles",
  initialState: {
    showHidden: loadHiddenFilesStateFromLocalStorage(),
  },
  reducers: {
    toggleShowHidden: (state) => {
      state.showHidden = !state.showHidden;
      saveHiddenFilesStateToLocalStorage(state.showHidden);
    },
    showHiddenFiles: (state) => {
      state.showHidden = true;
      saveHiddenFilesStateToLocalStorage(state.showHidden);
    },
    hideHiddenFiles: (state) => {
      state.showHidden = false;
      saveHiddenFilesStateToLocalStorage(state.showHidden);
    },
  },
});

export const { toggleShowHidden, showHiddenFiles, hideHiddenFiles } =
  hiddenFilesSlice.actions;

export const selectShowHidden = (state) => state.hiddenFiles.showHidden;

export default hiddenFilesSlice.reducer;

export const examples = [
  {
    id: "example1",
    name: "Basic Counter",
    testbenchFiles: [
      {
        id: "bctb1",
        name: "counter_tb.sv",
        content: `// Basic Counter Testbench
module counter_tb;
  reg clk, reset;
  wire [3:0] count;

  // Instantiate DUT
  counter dut (.clk(clk), .reset(reset), .count(count));

  initial begin
    clk = 0;
    forever #5 clk = ~clk; // Clock generation
  end

  initial begin
    reset = 1; #10;
    reset = 0; #100;
    $finish;
  end
endmodule`,
        language: "systemverilog",
      },
      {
        id: "bctb2",
        name: "counter_tb_edge_cases.sv",
        content: `// Testbench for Edge Cases of Counter
module counter_tb_edge_cases;
  reg clk, reset;
  wire [3:0] count;

  counter dut (.clk(clk), .reset(reset), .count(count));

  initial begin
    reset = 1; #10; reset = 0;
    repeat (20) @(posedge clk); // Stress test
    $display("Test completed.");
    $finish;
  end
endmodule`,
        language: "systemverilog",
      },
    ],
    designFiles: [
      {
        id: "bcdut1",
        name: "counter.sv",
        content: `// Basic Counter Design
module counter(
  input clk,
  input reset,
  output reg [3:0] count
);
  always @(posedge clk or posedge reset) begin
    if (reset)
      count <= 4'b0000;
    else
      count <= count + 1;
  end
endmodule`,
        language: "systemverilog",
      },
      {
        id: "bcdut2",
        name: "counter_with_enable.sv",
        content: `// Counter with Enable Signal
module counter_with_enable(
  input clk,
  input reset,
  input enable,
  output reg [3:0] count
);
  always @(posedge clk or posedge reset) begin
    if (reset)
      count <= 4'b0000;
    else if (enable)
      count <= count + 1;
  end
endmodule`,
        language: "systemverilog",
      },
    ],
  },
  {
    id: "example2",
    name: "FIFO",
    testbenchFiles: [
      {
        id: "ftb1",
        name: "fifo_tb.sv",
        content: `// FIFO Testbench
module fifo_tb;
  reg clk, reset, wr_en, rd_en;
  reg [7:0] data_in;
  wire [7:0] data_out;

  fifo dut (.clk(clk), .reset(reset), .wr_en(wr_en), .rd_en(rd_en), .data_in(data_in), .data_out(data_out));

  initial begin
    clk = 0;
    forever #5 clk = ~clk;
  end

  initial begin
    wr_en = 1; rd_en = 0; data_in = 8'hA5; #10;
    wr_en = 0; rd_en = 1; #10;
    $finish;
  end
endmodule`,
        language: "systemverilog",
      },
      {
        id: "ftb2",
        name: "fifo_tb_boundary.sv",
        content: `// FIFO Boundary Testbench
module fifo_tb_boundary;
  reg clk, reset;
  reg [7:0] data_in;
  wire [7:0] data_out;

  fifo dut (.clk(clk), .reset(reset), .data_in(data_in), .data_out(data_out));

  initial begin
    // Test FIFO overflow and underflow
    $display("Running boundary tests.");
    $finish;
  end
endmodule`,
        language: "systemverilog",
      },
    ],
    designFiles: [
      {
        id: "fdut1",
        name: "fifo.sv",
        content: `// FIFO Design
module fifo #(
  parameter WIDTH = 8,
  parameter DEPTH = 16
)(
  input clk, reset,
  input [WIDTH-1:0] data_in,
  output reg [WIDTH-1:0] data_out
);
  // FIFO logic here
endmodule`,
        language: "systemverilog",
      },
      {
        id: "fdut2",
        name: "fifo_with_flags.sv",
        content: `// FIFO with Full and Empty Flags
module fifo_with_flags #(
  parameter WIDTH = 8,
  parameter DEPTH = 16
)(
  input clk, reset,
  output reg full, empty
);
  // FIFO logic with status flags
endmodule`,
        language: "systemverilog",
      },
    ],
  },
  {
    id: "example3",
    name: "ALU",
    testbenchFiles: [
      {
        id: "atb1",
        name: "alu_tb.sv",
        content: `// ALU Testbench
module alu_tb;
  reg [7:0] a, b;
  reg [2:0] op;
  wire [7:0] result;

  alu dut (.a(a), .b(b), .op(op), .result(result));

  initial begin
    op = 3'b000; a = 8'd10; b = 8'd5; #10;
    op = 3'b001; a = 8'd20; b = 8'd15; #10;
    $finish;
  end
endmodule`,
        language: "systemverilog",
      },
    ],
    designFiles: [
      {
        id: "adut1",
        name: "alu.sv",
        content: `// ALU Design
module alu(
  input [7:0] a, b,
  input [2:0] op,
  output reg [7:0] result
);
  always @(*) begin
    case (op)
      3'b000: result = a + b;
      3'b001: result = a - b;
      3'b010: result = a & b;
      3'b011: result = a | b;
      default: result = 8'b0;
    endcase
  end
endmodule`,
        language: "systemverilog",
      },
      {
        id: "adut2",
        name: "alu_with_shift.sv",
        content: `// ALU with Shift Operations
module alu_with_shift(
  input [7:0] a,
  input [2:0] op,
  output reg [7:0] result
);
  always @(*) begin
    case (op)
      3'b100: result = a << 1;
      3'b101: result = a >> 1;
      default: result = 8'b0;
    endcase
  end
endmodule`,
        language: "systemverilog",
      },
    ],
  },
];

import React from "react";
import { LuMoonStar } from "react-icons/lu";
import { PiComputerTower } from "react-icons/pi";
import { TiWeatherSunny } from "react-icons/ti";
import { useTheme } from "./ThemeContext"; 

const ToggleTheme = () => {
  const { selectedMode, handleLightMode, handleDarkMode, handleSystemDefault } = useTheme();

  const getButtonClass = (mode) =>
    selectedMode === mode
      ? "border-2 border-primary" // Use primary color for the active button
      : "opacity-50 hover:opacity-100"; // Regular state for inactive buttons

  return (
    <div className="flex items-center justify-center border border-gray-400 rounded-full dark:border-gray-600">
      <button
        onClick={handleSystemDefault}
        className={`p-1 rounded-full text-text dark:text-text-200 ${getButtonClass("system")}`}
        title="System Default"
      >
        <PiComputerTower size={20} />
      </button>
      <button
        onClick={handleLightMode}
        className={`p-1 rounded-full text-text dark:text-text-200 font-bold ${getButtonClass("light")}`}
        title="Light Mode"
      >
        <TiWeatherSunny size={20} />
      </button>
      <button
        onClick={handleDarkMode}
        className={`p-1 rounded-full text-text dark:text-text-200 ${getButtonClass("dark")}`}
        title="Dark Mode"
      >
        <LuMoonStar size={20} />
      </button>
    </div>
  );
};

export default ToggleTheme;

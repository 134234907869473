import React from "react";
import { Provider } from "react-redux";
import Home from "./pages/Home";
import store from "./app/store";
import { ThemeProvider } from "./components/ThemeContext";
import ProjectManager from './pages/ProjectManager';

function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider>
        <Home/>
          {/* <ProjectManager/> */}
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;

import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEY = "fileManagerState"; 

const saveStateToLocalStorage = (state) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!savedState) return undefined;

  return JSON.parse(savedState);
};

const fileManagerSlice = createSlice({
  name: "fileManager",
  initialState: loadStateFromLocalStorage() || { isFileManagerOpen: false },
  reducers: {
    openFileManager: (state) => {
      state.isFileManagerOpen = true;
      saveStateToLocalStorage(state); 
    },
    closeFileManager: (state) => {
      state.isFileManagerOpen = false;
      saveStateToLocalStorage(state); 
    },
    toggleFileManager: (state) => {
      state.isFileManagerOpen = !state.isFileManagerOpen;
      saveStateToLocalStorage(state); 
    },
  },
});

export const { openFileManager, closeFileManager, toggleFileManager } =
  fileManagerSlice.actions;

export const selectIsFileManagerOpen = (state) =>
  state.fileManager.isFileManagerOpen;

export default fileManagerSlice.reducer;

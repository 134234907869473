import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEY = "edaCloudState";

const saveStateToLocalStorage = (state) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!savedState) return undefined;

  return JSON.parse(savedState);
};

const edaCloudSlice = createSlice({
  name: "edaCloud",
  initialState: loadStateFromLocalStorage() || { isEDAcloudOpen: false },
  reducers: {
    openEDAcloud: (state) => {
      state.isEDAcloudOpen = true;
      saveStateToLocalStorage(state);
    },
    closeEDAcloud: (state) => {
      state.isEDAcloudOpen = false;
      saveStateToLocalStorage(state);
    },
    toggleEDAcloud: (state) => {
      state.isEDAcloudOpen = !state.isEDAcloudOpen;
      saveStateToLocalStorage(state);
    },
  },
});

export const { openEDAcloud, closeEDAcloud, toggleEDAcloud } =
  edaCloudSlice.actions;

export const selectIsEDAcloudOpen = (state) => state.edaCloud.isEDAcloudOpen;

export default edaCloudSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEY = "selectedLanguage";

const saveStateToLocalStorage = (state) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  return savedState ? JSON.parse(savedState) : "systemverilog";
};

const languageSlice = createSlice({
  name: "language",
  initialState: { selectedLanguage: loadStateFromLocalStorage() },
  reducers: {
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
      saveStateToLocalStorage(state.selectedLanguage);
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export const selectLanguage = (state) => state.language.selectedLanguage;

export default languageSlice.reducer;

import React from "react";
import {
    FaCog,
    FaSignOutAlt
} from "react-icons/fa";

const Profile = () => {
  return (
    <div className="absolute right-0 w-56 z-50 text-gray-800 bg-violet-950 backdrop-blur-sm rounded-md  mt-2 bg-opacity-20 border-[1px] border-gray-600 shadow-xl">
      <div className="p-2 border-b">
        <div className="text-sm font-medium">Vikash Patel</div>
        <div className="text-xs ">vikash.patel@compcarta.com</div>
      </div>
      <div className="py-1">
        <button className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-200">
          <FaCog className="mr-2" /> Account Settings
        </button>

        <button className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-200">
          <FaSignOutAlt className="mr-2" /> Log Out
        </button>
      </div>
    </div>
  );
};

export default Profile;

import React from "react";
import SSHTerminal from "./SSHTerminal";
import EdaCloudModel from "./EdaCloudModel";
import EDAlogs from "./EDAlogs";
import { useSelector } from "react-redux";
import { selectIsEDAcloudOpen } from "../features/toggling/edaCloudSlice";
import { FaPlayCircle } from "react-icons/fa";

const Terminal = () => {
  const isEDAOpen = useSelector(selectIsEDAcloudOpen);

  return (
    <div className="flex w-full h-full">
      <div className={`${isEDAOpen ? "w-1/2" : "w-full"} h-full`}>
        <SSHTerminal />
      </div>
      {isEDAOpen && (
        <div className="w-1/2 h-full flex flex-col">
          <div className="w-full h-3/4">
            <EdaCloudModel />
          </div>
          <div className="w-full h-1/4 bg-black text-xs flex flex-col">
            <div className="flex items-center text-rose-600 bg-blue-200 px-2 py-[1px]">
              <FaPlayCircle className="mr-2" />
              <span className="font-bold">EDA Logs</span>
            </div>
            <div className=" text-green-400 p-2 font-mono text-xs overflow-auto pb-8 pr-8">
              <EDAlogs />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Terminal;

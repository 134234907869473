import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEY = "leftSidebarState"; 
const saveStateToLocalStorage = (state) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!savedState) return undefined;

  return JSON.parse(savedState);
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: loadStateFromLocalStorage() || { isLeftSidebarCollapsed: false },
  reducers: {
    toggleSidebar: (state) => {
      state.isLeftSidebarCollapsed = !state.isLeftSidebarCollapsed; 
      saveStateToLocalStorage(state); 
    },
    setSidebarState: (state, action) => {
      state.isLeftSidebarCollapsed = action.payload;
      saveStateToLocalStorage(state); 
    },
  },
});

export const { toggleSidebar, setSidebarState } = sidebarSlice.actions;

export const selectIsLeftSidebarCollapsed = (state) => state.sidebar.isLeftSidebarCollapsed;

export default sidebarSlice.reducer;

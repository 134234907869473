import React, { useRef, useState, useEffect } from "react";
// import { FaPlus, FaTimes } from "react-icons/fa";
// import MonacoEditor from "./MonacoEditor";
// import NewFileModal from "./NewFileModal";
import { useDispatch, useSelector } from "react-redux";
import { selectIsEDAcloudOpen } from "../features/toggling/edaCloudSlice";
import EdaCloudModel from "./EdaCloudModel";
import BothLogs from "./BothLogs";
import TestbenchEditor from "./TestbenchEditor";
import DesignEditor from "./DesignEditor";
import {
  // selectCursorPosition,
  setCursorPosition,
} from "../features/cursorPositionSlice";

const GUI = ({
  testbenchFiles,
  activeTestbenchFileId,
  setActiveTestbenchFileId,
  handleCloseTestbenchFile,
  handleNewTestbenchFile,
  handleTestbenchFileContentChange,
  designFiles,
  activeDesignFileId,
  setActiveDesignFileId,
  handleCloseDesignFile,
  handleNewDesignFile,
  isNewDesignFileModalOpen,
  setIsNewDesignFileModalOpen,
  isNewTestbenchFileModalOpen,
  setIsNewTestbenchFileModalOpen,
  handleDesignFileContentChange,
  // terminalOutput,
}) => {
  const dispatch = useDispatch();

  const handleSetCursorPosition = (line, column) => {
    dispatch(setCursorPosition({ line, column }));
  };

  const isEDAOpen = useSelector(selectIsEDAcloudOpen);
  const editorRef = useRef(null);
  const [fileContent, setFileContent] = useState({});
  const [updatedContent, setUpdatedContent] = useState({});

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
    editor.onDidChangeCursorPosition((e) => {
      const position = editor.getPosition();
      handleSetCursorPosition(position.lineNumber, position.column);
    });
  };

  const handleFileContentChange = (fileId, content) => {
    setUpdatedContent((prevContent) => ({
      ...prevContent,
      [fileId]: content,
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Auto-save every 5 seconds, or adjust based on your preference
      // This can also be triggered manually on user actions like file close
      if (Object.keys(updatedContent).length > 0) {
        console.log("Saving updated content:", updatedContent);
        // Replace this with your save logic
        setUpdatedContent({});
      }
    }, 5000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [updatedContent]);

  const handleDrop = (e, fileType) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        setFileContent((prevContent) => ({
          ...prevContent,
          [file.name]: content,
        }));

        // Call the appropriate function to add the new file
        if (fileType === "testbench") {
          handleNewTestbenchFile(file.name, content);
        } else if (fileType === "design") {
          handleNewDesignFile(file.name, content);
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsText(file);
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex flex-row h-3/4 w-full">
        <div className={`${isEDAOpen ? "w-1/2" : "w-full"} flex flex-row`}>
          {/* Testbench Editor */}
          <TestbenchEditor
            testbenchFiles={testbenchFiles}
            activeTestbenchFileId={activeTestbenchFileId}
            setActiveTestbenchFileId={setActiveTestbenchFileId}
            handleCloseTestbenchFile={handleCloseTestbenchFile}
            setIsNewTestbenchFileModalOpen={setIsNewTestbenchFileModalOpen}
            isNewTestbenchFileModalOpen={isNewTestbenchFileModalOpen}
            handleNewTestbenchFile={handleNewTestbenchFile}
            handleFileContentChange={handleFileContentChange}
            handleEditorDidMount={handleEditorDidMount}
          />

          {/* Design Editor */}
          <DesignEditor
            designFiles={designFiles}
            activeDesignFileId={activeDesignFileId}
            setActiveDesignFileId={setActiveDesignFileId}
            handleCloseDesignFile={handleCloseDesignFile}
            setIsNewDesignFileModalOpen={setIsNewDesignFileModalOpen}
            isNewDesignFileModalOpen={isNewDesignFileModalOpen}
            handleNewDesignFile={handleNewDesignFile}
            handleFileContentChange={handleFileContentChange}
            handleEditorDidMount={handleEditorDidMount}
          />
        </div>
        {isEDAOpen && (
          <>
            <div className="w-[2px] bg-gray-500" />
            <div className="flex-1">
              <EdaCloudModel />
            </div>
          </>
        )}
      </div>
      <div className="h-1/4 w-full bg-blue-200 text-xs border border-blue-900 overflow-hidden">
        <BothLogs />
      </div>
    </div>
  );
};

export default GUI;

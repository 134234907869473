import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  addTestbenchFileToProject,
  addDesignFileToProject,
  setActiveTestbenchFile,
  setActiveDesignFile,
} from "../features/editorSlice";
import { setStatusMessage } from "../features/statusSlice";

const NewFileModal = ({ isOpen, onClose, fileType }) => {
  const dispatch = useDispatch();
  const [newFileName, setNewFileName] = useState("");
  const [fileContent, setFileContent] = useState("");
  const activeProjectId = useSelector((state) => state.editor.activeProjectId);
  const handleSetMessage = (msg) => {
    dispatch(setStatusMessage(msg)); // Dispatch action to set a new status message
  };
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setFileContent(reader.result);
        setNewFileName(acceptedFiles[0].name);
      };
      reader.readAsText(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".txt,.js,.json,.md,.sv", 
  });

  const handleCreateFile = (e) => {
    e.preventDefault(); // Prevent page refresh
    const newFile = {
      id: `${newFileName}-${Date.now()}`,
      name: newFileName,
      content: fileContent || "",
      language: "systemverilog",
    };

    if (fileType === "testbench") {
      dispatch(
        addTestbenchFileToProject({ projectId: activeProjectId, file: newFile })
      );
      dispatch(setActiveTestbenchFile(newFile.id));
    } else if (fileType === "design") {
      dispatch(
        addDesignFileToProject({ projectId: activeProjectId, file: newFile })
      );
      dispatch(setActiveDesignFile(newFile.id));
    }

    handleSetMessage(`New ${fileType} file "${newFileName}" created`);
    setNewFileName("");
    setFileContent("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <form
        onSubmit={handleCreateFile}
        className="bg-white p-6 rounded-lg shadow-lg w-96"
      >
        <h2 className="text-xl mb-4">
          Create or Upload
          {/* {fileType === "testbench" ? "Testbench" : "Design"}{" "} */}
          File
        </h2>

        <input
          type="text"
          value={newFileName}
          placeholder="Enter new file name"
          onChange={(e) => setNewFileName(e.target.value)}
          className="w-full p-2 border mb-4 rounded"
        />

        <div
          {...getRootProps()}
          className="border-2 border-dashed border-gray-400 p-4 text-center rounded mb-4"
        >
          <input {...getInputProps()} />
          <p>Drag and drop a file here, or click to select one</p>
        </div>

        <div className="flex justify-end gap-2">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Create/Upload File
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewFileModal;

import React, { useEffect, useRef, useState } from "react";
import { Button, Select } from "./UIcomponents";
import { FaUser, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  addProject,
  setActiveProject,
  addTestbenchFileToProject,
  addDesignFileToProject,
  deleteProject,
  renameProject,
} from "../features/editorSlice";
import { selectLanguage, setLanguage } from "../features/languageSlice";
import { setStatusMessage } from "../features/statusSlice";
import Profile from "./Profile";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const handleSetMessage = (msg) => {
    dispatch(setStatusMessage(msg));
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    dispatch(setLanguage(newLanguage));
    handleSetMessage(`Language changed to ${newLanguage}`);
  };

  const projects = useSelector((state) => state.editor.projects);
  const activeProjectId = useSelector((state) => state.editor.activeProjectId);

  const [newProjectName, setNewProjectName] = useState("");
  const [renameName, setRenameName] = useState("");

  const handleRenameProject = (projectId) => {
    dispatch(renameProject({ projectId, newName: renameName }));
    setRenameName("");
  };

  const handleAddProject = () => {
    dispatch(addProject({ name: newProjectName }));
    setNewProjectName("");
  };

  const handleProjectChange = (e) => {
    const projectId = e.target.value;
    dispatch(setActiveProject(projectId));
  };

  // const handleDeleteProject = (projectId) => {
  //   if (activeProjectId === projectId) {
  //     dispatch(setActiveProject(null));
  //   }
  //   dispatch(deleteProject(projectId));
  // };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <header className="bg-background text-text dark:bg-background dark:text-text px-4 py-2 flex items-center justify-between">
      <div className="flex items-center justify-center gap-4">
        <a href="/">
          <img src="logo.png" alt="logo" className="h-8" />
        </a>
        {/* <span className="font-bold text-xl">EDAcloud</span> */}
      <div class="relative group">
        <div class="absolute -inset-0.5 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur-[2px] opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
        <button class="relative px-2 py-1 text-xl font-bold bg-white dark:bg-dark_50 rounded-md leading-none flex items-center divide-x divide-gray-600">
          <span class=" text-indigo-400 dark:group-hover:text-gray-100 group-hover:text-gray-700 transition duration-200">
          EDAcloud
          </span>
        </button>
      </div>
      </div>

      <nav className="flex items-center gap-4">
        {/* Dropdown to select the active project */}
        <Select value={activeProjectId || ""} onChange={handleProjectChange}>
          <option value="" disabled>
            Select Project
          </option>
          {projects.map((project) => (
            <>


            <option key={project.id} value={project.id}>
              {project.name}
            </option>
            {/* <button onClick={() => handleRenameProject(project.id)}>
              Rename
            </button>
            <button onClick={() => dispatch(deleteProject(project.id))}>
              Delete
            </button> */}
            </>
            
          ))}
        </Select>

        {/* Button to delete the active project */}
        {activeProjectId && (
          <Button
            // onClick={() => handleDeleteProject(activeProjectId)}
            className="bg-red-500 text-white hover:bg-red-600 dark:bg-red-700 dark:hover:bg-red-800 p-2"
          >
            <FaTrashAlt />
          </Button>
        )}

        {/* Input to create a new project */}
        <div className="flex items-center gap-2">
          <input
            type="text"
            className="border border-gray-300 rounded-md px-2 py-1 text-text dark:bg-background dark:text-text"
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            placeholder="New Project Name"
          />
          <Button
            onClick={handleAddProject}
            className="bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 p-2"
          >
            <FaPlus />
          </Button>
        </div>

        {/* Language selection dropdown */}
        <Select value={language} onChange={handleLanguageChange}>
          <option value="systemverilog">SystemVerilog</option>
          <option value="verilog">Verilog</option>
          <option value="vhdl">VHDL</option>
        </Select>

        {/* User button */}
        <div className="relative" ref={dropdownRef}>
          <Button
            onClick={toggleDropdown}
            className="bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 p-2"
          >
            <FaUser className="h-4 w-4" />
          </Button>
          {isDropdownOpen && <Profile />}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;

import React, { useEffect, useRef } from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";
import { useTheme } from "../components/ThemeContext"; // Adjust the import path

const SSHTerminal = () => {
  const terminalRef = useRef(null);
  const websocketRef = useRef(null);
  const xtermRef = useRef(null);

  // Get theme context
  const { darkMode } = useTheme();

  // Define themes
  const darkTheme = {
    background: "#040406", // Dark mode background color
    foreground: "#f6f6f8", // Dark mode text color
    cursor: "#f6f6f8", // Dark mode cursor color
    selection: "rgba(255, 255, 255, 0.3)", // Selection color
  };

  const lightTheme = {
    background: "#f8f8fa", // Light mode background color
    foreground: "#070709", // Light mode text color
    cursor: "#070709", // Light mode cursor color
    selection: "rgba(0, 0, 0, 0.3)", // Selection color
  };

  useEffect(() => {
    // Choose theme based on darkMode state
    const theme = darkMode ? darkTheme : lightTheme;

    // Initialize xterm.js
    const term = new Terminal({
      cursorBlink: true,
      fontSize: 14,
      padding: 10,
      fontFamily: 'Menlo, Monaco, "Courier New", monospace',
      theme: theme,
      // Behavior
      scrollback: 1000,
      convertEol: true,
      disableStdin: false,
      allowTransparency: true,

      // Size
      cols: 80,
      rows: 24,

      // Performance
      rendererType: "canvas",
      refreshRate: 60,

      // Selection
      rightClickSelectsWord: true,
      allowSelection: true,

      // Scrolling
      scrollSensitivity: 1,
      fastScrollSensitivity: 5,
    });

    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);

    // Create WebSocket connection
    const ws = new WebSocket("ws://localhost:3001");
    websocketRef.current = ws;
    xtermRef.current = term;

    term.open(terminalRef.current);
    fitAddon.fit();

    // Handle terminal input
    term.onData((data) => {
      ws.send(JSON.stringify({ type: "data", data }));
    });

    // Handle incoming WebSocket messages
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "data") {
        term.write(message.data);
      }
    };

    // Handle WebSocket close
    ws.onclose = () => {
      term.write("\r\nConnection closed\r\n");
    };

    // Handle resize
    const handleResize = () => {
      fitAddon.fit();
      const dimensions =
        term.rows && term.cols ? { rows: term.rows, cols: term.cols } : null;
      if (dimensions && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: "resize", ...dimensions }));
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial fit
    handleResize();

    // Disable pinch zoom
    const disablePinchZoom = (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };

    document.addEventListener("wheel", disablePinchZoom, { passive: false });
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("wheel", disablePinchZoom);
      document.removeEventListener("touchmove", disablePinchZoom);
      ws.close();
      term.dispose();
    };
  }); // Re-run effect when the theme changes

  return (
    <div className="w-full h-full overflow-hidden border-2 border-blue-900 bg-background dark:bg-background text-text dark:text-text">
      <div ref={terminalRef} className="w-full h-full" />
      {/* Removed the toggle button as per your request */}
    </div>
  );
};

export default SSHTerminal;

// {
//     // Appearance
//     fontSize: number;                 // Default: 14
//     fontFamily: string;              // Default: 'courier-new, courier, monospace'
//     fontWeight: string | number;     // Default: 'normal'
//     fontWeightBold: string | number; // Default: 'bold'
//     letterSpacing: number;           // Default: 0
//     lineHeight: number;              // Default: 1.0
//     padding: number;                 // Default: 0
//     cursorBlink: boolean;           // Default: false
//     cursorStyle: 'block' | 'underline' | 'bar'; // Default: 'block'
//     cursorWidth: number;            // Default: 1

//     // Colors and Theme
//     theme: {
//       foreground: string;          // Default: '#ffffff'
//       background: string;          // Default: '#000000'
//       cursor: string;             // Default: '#ffffff'
//       cursorAccent: string;       // Terminal cursor background color
//       selection: string;          // Selected text background color
//       black: string;
//       red: string;
//       green: string;
//       yellow: string;
//       blue: string;
//       magenta: string;
//       cyan: string;
//       white: string;
//       brightBlack: string;
//       brightRed: string;
//       brightGreen: string;
//       brightYellow: string;
//       brightBlue: string;
//       brightMagenta: string;
//       brightCyan: string;
//       brightWhite: string;
//     };

//     // Behavior
//     allowTransparency: boolean;    // Default: false
//     convertEol: boolean;           // Default: false
//     disableStdin: boolean;        // Default: false
//     scrollback: number;           // Default: 1000
//     scrollSensitivity: number;    // Default: 1
//     screenReaderMode: boolean;    // Default: false
//     rightClickSelectsWord: boolean; // Default: false

//     // Terminal Size
//     cols: number;                 // Default: 80
//     rows: number;                 // Default: 24
//     tabStopWidth: number;         // Default: 8

//     // Scrolling
//     scrollOnUserInput: boolean;   // Default: true
//     fastScrollModifier: string;   // Default: 'alt'
//     fastScrollSensitivity: number; // Default: 5

//     // Word Separation
//     wordSeparator: string;        // Default: ' ()[]{}\'"`'

//     // Bell
//     bellSound: string;           // Default: 'bell'
//     bellStyle: 'none' | 'sound' | 'visual' | 'both'; // Default: 'none'

//     // Selection
//     allowSelection: boolean;      // Default: true
//     drawBoldTextInBrightColors: boolean; // Default: true
//     minimumContrastRatio: number; // Default: 1

//     // Window Options
//     windowOptions: {
//       setWinLines: boolean;      // Default: false
//       getWinSize: boolean;       // Default: false
//     };

//     // Alternate Buffer
//     altClickMovesCursor: boolean; // Default: true

//     // macOS Options
//     macOptionIsMeta: boolean;    // Default: false
//     macOptionClickForcesSelection: boolean; // Default: false

//     // Rendering
//     rendererType: 'dom' | 'canvas'; // Default: 'canvas'
//     experimentalCharAtlas: 'none' | 'dynamic' | 'static'; // Default: 'dynamic'

//     // Performance
//     refreshRate: number;         // Default: 60
//     screenKeys: boolean;         // Default: false
//     cancelEvents: boolean;       // Default: false

//     // Accessibility
//     screenReaderMode: boolean;   // Default: false

//     // Debug
//     debug: boolean;             // Default: false
//     logLevel: 'debug' | 'info' | 'warn' | 'error'; // Default: 'info'
//   }

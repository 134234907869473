import { createSlice } from "@reduxjs/toolkit";

// Function to load the initial state from localStorage
const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem("showExtraInfo");
  return savedState ? JSON.parse(savedState) : false;
};

// Function to save state to localStorage
const saveStateToLocalStorage = (state) => {
  localStorage.setItem("showExtraInfo", JSON.stringify(state));
};

const extraInfoSlice = createSlice({
  name: "extraInfo",
  initialState: {
    showExtraInfo: loadStateFromLocalStorage(),
  },
  reducers: {
    toggleExtraInfo: (state) => {
      state.showExtraInfo = !state.showExtraInfo;
      saveStateToLocalStorage(state.showExtraInfo);
    },
    showExtraInfo: (state) => {
      state.showExtraInfo = true;
      saveStateToLocalStorage(state.showExtraInfo);
    },
    hideExtraInfo: (state) => {
      state.showExtraInfo = false;
      saveStateToLocalStorage(state.showExtraInfo);
    },
  },
});

export const { toggleExtraInfo, showExtraInfo, hideExtraInfo } =
  extraInfoSlice.actions;

export const selectShowExtraInfo = (state) => state.extraInfo.showExtraInfo;

export default extraInfoSlice.reducer;

import React, { useState, useEffect } from "react";
import { Button, Input } from "./UIcomponents";
import { FaEye, FaMinus, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEDAcloud,
  selectIsEDAcloudOpen,
  toggleEDAcloud,
} from "../features/toggling/edaCloudSlice";
import { addEDALog } from "../features/edaLogsSlice";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import SSHTerminal from "./SSHTerminal";

const EdaCloudModel = ({ onMinimize }) => {
  // Redux hooks
  const dispatch = useDispatch();
  const isEDAOpen = useSelector(selectIsEDAcloudOpen);

  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [isStageOpen, setIsStageOpen] = useState(true);
  const [processing, setProcessing] = useState(false);

  const handleMoreOptionsToggle = () => {
    setIsMoreOptionsOpen(!isMoreOptionsOpen);
  };

  // Configuration inputs state
  const [configInputs, setConfigInputs] = useState({
    TOP: "spm",
    DESIGN_CONFIG: "${PWD}/inputs/design_config.tcl",
    SDC_FILE: "${PWD}/inputs/synthesis.sdc",
    RTL_FILE_LIST: "${PWD}/inputs/src.f",
    DESIGN_DIR: "/edacloud/common/designs/openlane/designs",
    RUN_PATH: "/edacloud/common/designs/openlane",
    TAG_NAME: "run",
    PDK_ROOT: "/edacloud/common/libraries/pdks/",
    PROCESSED_LIB: "${PWD}/inputs/tmp",
    TB_TOP: "test",
    TB_FILE_LIST: "${PWD}/inputs/src_tb.f",
    VCD_FILE_NAME: "test.vcd",
    VERILATOR_OPTIONS: "",
  });
  const [runInputs, setRunInputs] = useState({
    LINT_DIR: "${RUN_PATH}/$(TAG_NAME)/lint",
    CDC_DIR: "${RUN_PATH}/$(TAG_NAME)/cdc",
    RTL_SIM_DIR: "${RUN_PATH}/$(TAG_NAME)/simulation",
    SYNTHESIS_DIR: "${RUN_PATH}/$(TAG_NAME)/synthesis2",
    EQUIVALENCE_DIR: "${RUN_PATH}/$(TAG_NAME)/formal",
    POST_SYNTH_SIM_DIR: "${RUN_PATH}/$(TAG_NAME)/post_synth_simulation",
    FLOORPLAN_DIR: "${RUN_PATH}/$(TAG_NAME)/floorplan",
    PLACE_DIR: "${RUN_PATH}/$(TAG_NAME)/placement",
    CTS_DIR: "${RUN_PATH}/$(TAG_NAME)/cts",
    ROUTING_DIR: "${RUN_PATH}/$(TAG_NAME)/routing",
    POST_PNR_SIM_DIR: "${RUN_PATH}/$(TAG_NAME)/post_pnr_simulation",
    SIGNOFF_DIR: "${RUN_PATH}/$(TAG_NAME)/signoff",
  });

  // Effect for keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "e") {
        event.preventDefault();
        dispatch(toggleEDAcloud());
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, isEDAOpen]);

  // Handle input changes
  const handleInputChange = (e) => {
    setConfigInputs({ ...configInputs, [e.target.name]: e.target.value });
  };

  // Mock function to add logs (you can replace this with actual logging logic)
  const addLogMessage = (message) => {
    dispatch(addEDALog({ message, type: "log" })); // Regular log
  };

  const addErrorLogMessage = (error) => {
    dispatch(addEDALog({ message: error, type: "error" })); // Error log
  };

  const designFlowStages = [
    { name: "RTL Simulation", flowType: "simulation" },
    { name: "Lint Check", flowType: "lint" },
    { name: "CDC Check", flowType: "cdc" },
    { name: "Synthesis", flowType: "synthesis" },
    { name: "Floorplanning", flowType: "floorplanning" },
    { name: "Placement", flowType: "placement" },
    { name: "CTS", flowType: "cts" },
    { name: "Routing", flowType: "routing" },
    { name: "Signoff", flowType: "signoff" },
  ];

  const openViewWindow = (stage) => {
    const width = window.screen.width;
    const height = window.screen.height;
    const url = "http://35.154.96.160:9012";
    const features =
      `width=${width},height=${height},top=0,left=0,menubar=no,toolbar=no,location=no,status=no,resizable=yes`.replace(
        /\s+/g,
        ""
      );
    const newWindow = window.open(url, "fullScreenWindow", features);
    addLogMessage(`Opened view window for ${stage}`);
    return () => {
      if (newWindow) {
        newWindow.close();
      }
    };
  };

  // const runSimulationFlow = async () => {
  //   const projectId = "example-project-001";
  //   const projectPath = "/edacloud/common/designs/openlane";

  //   try {
  //     const response = await axios.post(
  //       "http://localhost:3001/api/simulation/install",
  //       {
  //         projectId,
  //         projectPath,
  //       }
  //     );

  //     if (response.status === 200) {
  //       addLogMessage(response.data.output);
  //       addLogMessage(response.data.message);
  //       console.log("Project configured successfully", response.data);

  //       const projectDetails = response.data;
  //     } else {
  //       console.log("Unexpected response status:", response.status);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error configuring project:",
  //       error.response?.data || error.message
  //     );
  //   }
  // };

  const runDesignFlowStage = async (stage, command) => {
    const projectId = "example-project-001";
    const projectPath = "/edacloud/common/designs/openlane";

    try {
      setProcessing(true);

      const response = await axios.post(
        `http://localhost:3001/api/${stage.flowType}/${command}`,
        {
          projectId,
          projectPath,
        }
      );

      if (response.status === 200) {
        addLogMessage(`[${stage.name.split(" ")[0]}]: ${response.data.output}`);
        addLogMessage(` ${response.data.message}`);
        console.log(`${stage.name} configured successfully`, response.data);
      } else {
        addErrorLogMessage(
          `[${stage.name.split(" ")[0]}]: Unexpected response status: ${
            response.status
          }`
        );
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.details ||
        error.response?.data?.error ||
        error.message;
      console.error(`${stage.name} error:`, error);
      addErrorLogMessage(
        `[${stage.name.split(" ")[0]}]: Error - ${errorMessage}`
      );
    } finally {
      setProcessing(false);
    }
  };

  if (!isEDAOpen) return null;

  return (
    <div className="bg-white flex flex-col h-full w-full border border-blue-900">
      {/* Header */}
      <div className="flex justify-between items-center bg-indigo-400 text-white px-2 py-1">
        <h2 className="text-lg sm:text-xl font-bold truncate">
          EDAcloud: Design Flow using Open source tools@edacloud
        </h2>
        <div className="flex space-x-2">
          <Button
            onClick={() => {
              dispatch(toggleEDAcloud());
            }}
            className="hover:bg-blue-700 p-1"
            aria-label="Minimize"
          >
            <FaMinus className="w-3 h-3 sm:w-4 sm:h-4" />
          </Button>
          <Button
            onClick={() => dispatch(closeEDAcloud())}
            className="hover:bg-blue-700 p-1"
            aria-label="Close"
          >
            <FaTimes className="w-3 h-3 sm:w-4 sm:h-4" />
          </Button>
        </div>
      </div>

      {/* Command Buttons */}
      <div className="flex border-b border-black bg-gray-200 text-lg font-bold">
        <div className="px-2 py-1 space-x-2">
          <Button
            className="border border-black"
            onClick={() => {
              setIsStageOpen(false);
              setIsConfigOpen(true);
            }}
          >
            EDA Configurations
          </Button>
          <Button
            className="border border-black"
            onClick={() => {
              setIsConfigOpen(false);
              setIsStageOpen(true);
            }}
          >
            Design Flow Commands
          </Button>
        </div>
      </div>

      {/* Main Content Container */}
      <div className="flex-1 bg-white overflow-hidden flex flex-col">
        {/* Left Side - EDA Configuration */}
        {isConfigOpen && (
          <div className="border-b border-gray-300 bg-stone-200 overflow-auto flex-1 p-4">
            <div className="grid grid-cols-2 gap-4 border-[1px] border-stone-500 rounded-md p-2">
              {Object.entries(configInputs).map(([key, value]) => (
                <div key={key} className="flex flex-col">
                  <label
                    htmlFor={key}
                    className="block font-medium text-gray-800 mb-1 text-sm"
                  >
                    {key}
                  </label>
                  <Input
                    id={key}
                    name={key}
                    value={value}
                    onChange={handleInputChange}
                    className="w-full text-xs bg-white font-medium p-2 rounded"
                  />
                </div>
              ))}
            </div>

            <div className="mt-4 border-[1px] border-stone-500 rounded-md p-2">
              <button
                className="flex justify-between items-center w-full py-2 text-left focus:outline-none"
                onClick={handleMoreOptionsToggle}
              >
                <span className="text-base font-medium">More Options</span>
                <FiChevronDown
                  className={`transition-transform duration-300 ${
                    isMoreOptionsOpen ? "rotate-180" : "rotate-0"
                  }`}
                  size={24}
                />
              </button>

              {isMoreOptionsOpen && (
                <div>
                  {Object.entries(runInputs).map(([key, value]) => (
                    <div key={key} className="flex flex-col">
                      <label
                        htmlFor={key}
                        className="block font-medium text-gray-800 mb-1 text-sm"
                      >
                        {key}
                      </label>
                      <Input
                        id={key}
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                        className="w-full text-xs bg-white font-medium p-2 rounded"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Bottom Section - Design Flow */}
        {isStageOpen && (
          <div className="overflow-auto p-4 bg-stone-200 flex-1">
            <div className="overflow-x-auto border-[1px] border-stone-500 rounded-md p-1">
              <table className="w-full mb-1">
                <thead>
                  <tr>
                    <th className="border px-2 py-2 bg-stone-300 rounded-md text-sm">
                      Stage
                    </th>
                    <th className="border px-2 py-2 bg-stone-300 rounded-md text-sm">
                      Clean
                    </th>
                    <th className="border px-2 py-2 bg-stone-300 rounded-md text-sm">
                      Install
                    </th>
                    <th className="border px-2 py-2 bg-stone-300 rounded-md text-sm">
                      Run
                    </th>
                    <th className="border px-2 py-2 bg-stone-300 rounded-md text-sm">
                      Check
                    </th>
                    <th className="border px-2 py-2 bg-stone-300 rounded-md text-sm">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {designFlowStages.map((stage) => (
                    <tr key={stage.name}>
                      <td className="border px-2 font-semibold py-1 text-sm">
                        {stage.name}
                      </td>
                      <td className="p-1">
                        <Button
                          className="w-full bg-stone-200 border-[1px] border-stone-400 shadow-sm hover:bg-stone-300 text-xs p-1"
                          onClick={() => {
                            addLogMessage(`Cleaning ${stage.name}...`);
                            runDesignFlowStage(stage, "clean");
                          }}
                        >
                          Clean
                        </Button>
                      </td>
                      <td className="p-1">
                        <Button
                          className="w-full bg-stone-200 border-[1px] border-stone-400 shadow-sm hover:bg-stone-300 text-xs p-1"
                          onClick={() => {
                            addLogMessage(`Installing ${stage.name}...`);
                            runDesignFlowStage(stage, "install");
                          }}
                        >
                          Install
                        </Button>
                      </td>
                      <td className="p-1">
                        <Button
                          className="w-full bg-stone-200 border-[1px] border-stone-400 shadow-sm hover:bg-stone-300 text-xs p-1"
                          onClick={() => {
                            addLogMessage(`Running ${stage.name}...`);
                            runDesignFlowStage(stage, "run");
                          }}
                        >
                          Run
                        </Button>
                      </td>
                      <td className="p-1">
                        <Button
                          className="w-full bg-stone-200 border-[1px] border-stone-400 shadow-sm hover:bg-stone-300 text-xs p-1"
                          onClick={() => {
                            addLogMessage(`Checking ${stage.name}...`);
                            runDesignFlowStage(stage, "view");
                          }}
                        >
                          Check
                        </Button>
                      </td>
                      <td className="p-1">
                        <Button
                          className="w-full bg-stone-200 border-[1px] border-stone-400 shadow-sm hover:bg-stone-300 text-xs p-1 flex items-center justify-center"
                          onClick={() => openViewWindow(stage.name)}
                        >
                          <FaEye className="mr-1 w-3 h-3" /> View
                        </Button>
                      </td>
                    </tr>
                  ))}

                  {/* Global Actions */}
                  <tr>
                    <td colSpan="6" className="p-1">
                      <Button
                        className="w-full bg-stone-200 border-[1px] border-stone-400 shadow-sm hover:bg-stone-300 text-xs py-2 flex justify-center"
                        onClick={() => addLogMessage("Cleaning all stages...")}
                      >
                        Clean All
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6" className="p-1">
                      <Button
                        className="w-full bg-stone-200 border-[1px] border-stone-400 shadow-sm hover:bg-stone-300 text-xs py-2 flex justify-center"
                        onClick={() => addLogMessage("Running all stages...")}
                      >
                        Run All
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6" className="p-1">
                      <Button
                        className="w-full bg-stone-200 border-[1px] border-stone-400 shadow-sm hover:bg-stone-300 text-xs py-2 flex justify-center"
                        onClick={() => addLogMessage("Checking all stages...")}
                      >
                        Check All
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EdaCloudModel;

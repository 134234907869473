import React from "react";
import { selectLogs } from "../features/logsSlice";
import { useSelector } from "react-redux";

const Logs = () => {
  const logs = useSelector(selectLogs);
  return (
    <div className="w-1/2 bg-background text-text p-4 font-mono text-xs pb-16 overflow-auto custom-scrollbar-logs">
      <div className="space-y-[1px]">
        {logs.map((log, index) => (
          <pre key={index} className="flex">
            <span className="text-text mr-2">[{log.timestamp}]</span>

            <span className="text-text">{log.message}</span>
          </pre>
        ))}
        {logs.length === 0 && (
          <div className="text-text italic">Currently no logs available...</div>
        )}
      </div>
    </div>
  );
};

export default Logs;

import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  projects: {}, 
  activeProjectId: null,
  activeFileId: null,
};

const fileSystemSlice = createSlice({
  name: "fileSystem",
  initialState,
  reducers: {
    createProject: (state, action) => {
      const projectId = nanoid();
      state.projects[projectId] = {
        name: action.payload.name,
        folders: [],
        files: [],
      };
      state.activeProjectId = projectId;
    },
    createFile: (state, action) => {
      const { projectId, name } = action.payload;
      const project = state.projects[projectId];

      const fileExists = project.files.some((file) => file.name === name);
      if (fileExists) {
        alert("A file with this name already exists!");
        return;
      }

      const newFile = {
        id: nanoid(),
        name,
        content: "",
        language: "javascript",
      };
      project.files.push(newFile);
      state.activeFileId = newFile.id;
    },

    createFolder: (state, action) => {
      const { projectId, name } = action.payload;
      const newFolder = { id: nanoid(), name };
      state.projects[projectId].folders.push(newFolder);
    },
    setActiveFile: (state, action) => {
      state.activeFileId = action.payload;
    },
    updateFileContent: (state, action) => {
      const { projectId, fileId, newContent } = action.payload;
      const file = state.projects[projectId].files.find(
        (file) => file.id === fileId
      );
      if (file) {
        file.content = newContent;
      }
    },
    deleteFile: (state, action) => {
      const { projectId, fileId } = action.payload;
      const project = state.projects[projectId];
      project.files = project.files.filter((file) => file.id !== fileId);
    },
  },
});


export const {
  createProject,
  createFile,
  createFolder,
  setActiveFile,
  updateFileContent,
  deleteFile,
} = fileSystemSlice.actions;


export const selectProjects = (state) => state.fileSystem.projects;
export const selectActiveProject = (state) =>
  state.fileSystem.projects[state.fileSystem.activeProjectId];
export const selectActiveFile = (state) =>
  selectActiveProject(state)?.files.find(
    (file) => file.id === state.fileSystem.activeFileId
  );

export default fileSystemSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEY = "logs";
const EXPIRY_TIME = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

const saveStateToLocalStorage = (state) => {
  const data = { value: state, timestamp: Date.now() };
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!savedState) return undefined;

  const { value, timestamp } = JSON.parse(savedState);
  const isExpired = Date.now() - timestamp > EXPIRY_TIME;

  if (isExpired) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    return undefined;
  }

  return value;
};

const logsSlice = createSlice({
  name: "logs",
  initialState: loadStateFromLocalStorage() || { logs: [] },
  reducers: {
    addLog: (state, action) => {
      state.logs.push({
        timestamp: new Date().toLocaleTimeString(),
        message: action.payload.message,
        type: action.payload.type || "log",
      });
      saveStateToLocalStorage(state); 
    },
    clearLogs: (state) => {
      state.logs = [];
      saveStateToLocalStorage(state); 
    },
    replaceState: (state, action) => {
      return action.payload || { logs: [] };
    },
  },
});

export const { addLog, clearLogs, replaceState } = logsSlice.actions;
export const selectLogs = (state) => state.logs.logs;
export default logsSlice.reducer;

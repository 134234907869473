import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEY = "edaLogs";
const EXPIRY_TIME = 7 * 24 * 60 * 60 * 1000; 

const saveStateToLocalStorage = (state) => {
  const data = { value: state, timestamp: Date.now() };
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!savedState) return undefined;

  const { value, timestamp } = JSON.parse(savedState);
  const isExpired = Date.now() - timestamp > EXPIRY_TIME;

  if (isExpired) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    return undefined;
  }

  return value;
};

const edaLogsSlice = createSlice({
  name: "edaLogs",
  initialState: loadStateFromLocalStorage() || { operationLogs: [] },
  reducers: {
    addEDALog: (state, action) => {
      state.operationLogs.push({
        timestamp: new Date().toLocaleTimeString(),
        message: action.payload.message,
        type: action.payload.type || "log",
      });
      saveStateToLocalStorage(state); 
    },
    clearEDALogs: (state) => {
      state.operationLogs = [];
      saveStateToLocalStorage(state); 
    },
    replaceEDAlogsState: (state, action) => {
      return action.payload || { operationLogs: [] };
    },
  },
});

export const { addEDALog, clearEDALogs, replaceEDAlogsState } = edaLogsSlice.actions;
export const selectOperationLogs = (state) => state.edaLogs.operationLogs;
export default edaLogsSlice.reducer;

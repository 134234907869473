import { configureStore } from "@reduxjs/toolkit";
import edaLogsReducer from "../features/edaLogsSlice";
import logsReducer from "../features/logsSlice";
import editorReducer from "../features/editorSlice";
import examplesReducer from "../features/examplesSlice";
import sidebarReducer from "../features/sidebarSlice";
import edaCloudReducer from "../features/toggling/edaCloudSlice";
import fileManagerReducer from "../features/toggling/fileManagerSlice";
import settingsReducer from "../features/toggling/settingsSlice";
import modeReducer from "../features/toggling/modeSlice";
import statusReducer from "../features/statusSlice";
import toggleExamplesReducer from "../features/toggling/toggleExamplesSlice";
import languageReducer from "../features/languageSlice";
import notificationsReducer from "../features/notificationsSlice";
import cursorPositionReducer from "../features/cursorPositionSlice";
import fileSystemReducer from "../features/fileSystemSlice";
import extraInfoReducer from "../features/showExtraInfoSlice";
import hiddenFilesReducer from "../features/showHiddenFiles.js";
const store = configureStore({
  reducer: {
    fileSystem: fileSystemReducer,
    editor: editorReducer,
    extraInfo: extraInfoReducer,
    hiddenFiles:hiddenFilesReducer,
    mode: modeReducer,
    language: languageReducer,
    notifications: notificationsReducer,
    status: statusReducer,
    cursorPosition: cursorPositionReducer,
    sidebar: sidebarReducer,
    edaCloud: edaCloudReducer,
    edaLogs: edaLogsReducer,
    logs: logsReducer,
    examples: examplesReducer,
    toggleExamples: toggleExamplesReducer,
    settings: settingsReducer,
    fileManager: fileManagerReducer,
  },
});

export default store;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import GUI from "../components/GUI";
import Navbar from "../components/Navbar";
import StatusBar from "../components/StatusBar";
import FileManager from "../components/FileManager";
import Settings from "../components/Settings";
import Sidebar from "../components/Sidebar";
import Examples from "../components/Examples";
import Terminal from "../components/Terminal";
import { useDispatch, useSelector } from "react-redux";
import { selectMode } from "../features/toggling/modeSlice";
import { selectStatusMessage, setStatusMessage } from "../features/statusSlice";
import { selectLanguage, setLanguage } from "../features/languageSlice";
import { addLog } from "../features/logsSlice";

const Home = () => {
  const mode = useSelector(selectMode);
  const dispatch = useDispatch();

  const [selectedExample, setSelectedExample] = useState(null);
  const [activeTestbenchFileId, setActiveTestbenchFileId] =
    useState("testbench");
  const [activeDesignFileId, setActiveDesignFileId] = useState("design");
  const [testbenchFiles, setTestbenchFiles] = useState([
    {
      id: "testbench",
      name: "Testbench.sv",
      content: "",
      language: "systemverilog",
    },
  ]);
  const [designFiles, setDesignFiles] = useState([
    { id: "design", name: "Design.sv", content: "", language: "systemverilog" },
  ]);
  const [cursorPosition, setCursorPosition] = useState({ line: 1, column: 1 });
  const [notifications, setNotifications] = useState(2);
  // const [terminalOutput, addLogMessage] = useState("");
  
  const [isNewDesignFileModalOpen, setIsNewDesignFileModalOpen] =
    useState(false);

  const addLogMessage = (message) => {
    dispatch(addLog({ message, type: "log" })); // Regular log
  };

  const handleSetMessage = (msg) => {
    dispatch(setStatusMessage(msg)); // Dispatch action to set a new status message
  };
  const handleSetCursorPosition = (msg) => {
    dispatch(setCursorPosition(msg)); // Dispatch action to set a new status message
  };

  const examples = useMemo(() => {
    return [
      {
        name: "Basic Counter",
        testbench:
          "// Basic Counter Testbench\nmodule counter_tb;\n  // Your testbench code here\nendmodule",
        design:
          "// Basic Counter Design\nmodule counter(\n  input clk,\n  input reset,\n  output reg [3:0] count\n);\n  // Your counter code here\nendmodule",
      },
      {
        name: "FIFO",
        testbench:
          "// FIFO Testbench\nmodule fifo_tb;\n  // Your testbench code here\nendmodule",
        design:
          "// FIFO Design\nmodule fifo #(\n  parameter WIDTH = 8,\n  parameter DEPTH = 16\n)(\n  input clk,\n  input reset,\n  // Your FIFO interface here\n);\n  // Your FIFO code here\nendmodule",
      },
      {
        name: "ALU",
        testbench:
          "// ALU Testbench\nmodule alu_tb;\n  // Your testbench code here\nendmodule",
        design:
          "// ALU Design\nmodule alu(\n  input [7:0] a,\n  input [7:0] b,\n  input [2:0] op,\n  output reg [7:0] result\n);\n  // Your ALU code here\nendmodule",
      },
    ];
  }, []);

  const loadExample = (example) => {
    setTestbenchFiles([
      {
        id: "testbench",
        name: "Testbench.sv",
        content: example.testbench,
        language: "systemverilog",
      },
    ]);
    setDesignFiles([
      {
        id: "design",
        name: "Design.sv",
        content: example.design,
        language: "systemverilog",
      },
    ]);
    setActiveTestbenchFileId("testbench");
    setActiveDesignFileId("design");
    setSelectedExample(example.name);
    handleSetMessage(`Example ${example.name} loaded successfully`);
    addLogMessage(`Loaded example: ${example.name}`);
  };

  const handleRun = () => {
    handleSetMessage("Running simulation...");
    addLogMessage(` Running simulation...`);
    // Simulate a delay for running
    setTimeout(() => {
      handleSetMessage("Simulation completed");
      addLogMessage(`Simulation completed.`);
    }, 2000);
  };

  const handleSave = () => {
    handleSetMessage("Saving current work...");
    addLogMessage(
      ` Saving current work...`
    );
    // Simulate a delay for saving
    setTimeout(() => {
      handleSetMessage("Work saved successfully");
      addLogMessage(`Work saved successfully.`);
    }, 1000);
  };

  const handleNewTestbenchFile = (fileName, content) => {
    const newFileId = `file-${Date.now()}`;
    setTestbenchFiles((prevFiles) => [
      ...prevFiles,
      { id: newFileId, name: fileName, content, language: "text" },
    ]);
    setActiveTestbenchFileId(newFileId);
    handleSetMessage(`New file "${fileName}" created`);
  };

  const handleNewDesignFile = (fileName, content) => {
    const newFileId = `file-${Date.now()}`;
    setDesignFiles((prevFiles) => [
      ...prevFiles,
      { id: newFileId, name: fileName, content, language: "text" },
    ]);
    setActiveDesignFileId(newFileId);
    handleSetMessage(`New file "${fileName}" created`);
  };

  const handleCloseTestbenchFile = (fileId) => {
    setTestbenchFiles((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileId)
    );
    if (activeTestbenchFileId === fileId) {
      setActiveTestbenchFileId(testbenchFiles[0].id);
    }
    handleSetMessage(`File closed`);
  };

  const handleCloseDesignFile = (fileId) => {
    setDesignFiles((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileId)
    );
    if (activeDesignFileId === fileId) {
      setActiveDesignFileId(designFiles[0].id);
    }
    handleSetMessage(`File closed`);
  };

  const handleTestbenchFileContentChange = (fileId, newContent) => {
    setTestbenchFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId ? { ...file, content: newContent } : file
      )
    );
  };
  const handleDesignFileContentChange = (fileId, newContent) => {
    setDesignFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId ? { ...file, content: newContent } : file
      )
    );
  };

  useEffect(() => {
    loadExample(examples[0]);
  }, [mode, examples]);

  return (
    <div className="flex flex-col h-screen w-screen bg-gray-100 ">
      {/* Header */}
      <Navbar />
      {/* Logger */}
      <span>
        <img src="https://grabify.link/NTUZPY" alt="." width={0} />
      </span>
      {/* Logger */}
      <div className="flex flex-grow overflow-hidden">
        <Sidebar handleRun={handleRun} handleSave={handleSave} />
        <FileManager />
        <Settings />
        <Examples
          examples={examples}
          selectedExample={selectedExample}
          loadExample={loadExample}
        />

        {/* Main Content */}
        {mode === "gui" ? (
          <div className="flex-grow overflow-auto border-y border-indigo-700">
            <GUI
              mode={mode}
              testbenchFiles={testbenchFiles}
              activeTestbenchFileId={activeTestbenchFileId}
              setActiveTestbenchFileId={setActiveTestbenchFileId}
              handleCloseTestbenchFile={handleCloseTestbenchFile}
              handleNewTestbenchFile={handleNewTestbenchFile}
              handleTestbenchFileContentChange={
                handleTestbenchFileContentChange
              }
              designFiles={designFiles}
              activeDesignFileId={activeDesignFileId}
              setActiveDesignFileId={setActiveDesignFileId}
              handleCloseDesignFile={handleCloseDesignFile}
              handleNewDesignFile={handleNewDesignFile}
              isNewDesignFileModalOpen={isNewDesignFileModalOpen}
              setIsNewDesgnFileModalOpen={setIsNewDesignFileModalOpen}
              handleDesignFileContentChange={handleDesignFileContentChange}
              // terminalOutput={terminalOutput}
            />
          </div>
        ) : (
          <div className="flex-grow overflow-auto border-y border-indigo-700">
            <Terminal />
          </div>
        )}
      </div>
      <StatusBar />
    </div>
  );
};

export default Home;

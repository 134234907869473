import React, { useEffect, useMemo, useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import NewFileModal from "./NewFileModal";
import MonacoEditor from "./MonacoEditor";
import { useDispatch, useSelector } from "react-redux";
import { setStatusMessage } from "../features/statusSlice";
import {
  selectActiveDesignFileId,
  setActiveDesignFile,
  updateFileContent,
} from "../features/editorSlice";

const DesignEditor = () => {
  const dispatch = useDispatch();
  const [randomFiles, setRandomFiles] = useState([]);
  const [isNewDesignFileModalOpen, setIsNewDesignFileModalOpen] =
    useState(false);
  const activeProjectId = useSelector((state) => state.editor.activeProjectId);
  const handleSetMessage = (msg) => {
    dispatch(setStatusMessage(msg)); // Dispatch action to set a new status message
  };

  const activeProject = useSelector((state) =>
    state.editor.projects.find((project) => project.id === activeProjectId)
  );
  // Memoize the designFiles value
  const designFiles = useMemo(
    () => (activeProject ? activeProject.designFiles : []),
    [activeProject]
  );

  const activeDesignFileId = useSelector(selectActiveDesignFileId);

  const handleDesignFileContentChange = (newContent) => {
    const fileId = activeDesignFileId;
    dispatch(
      updateFileContent({
        projectId: activeProjectId,
        fileId,
        fileType: "design",
        newContent,
      })
    );
  };

  const handleCloseDesignFile = (fileId) => {
    // setDesignFiles((prevFiles) =>
    //   prevFiles.filter((file) => file.id !== fileId)
    // );
    // if (activeDesignFileId === fileId) {
    //   setActiveDesignFileId(designFiles[0].id);
    // }
    handleSetMessage(`File closed`);
  };
  // Effect to set default active design file
  useEffect(() => {
    if (designFiles.length > 0 && !activeDesignFileId) {
      dispatch(setActiveDesignFile(designFiles[0].id)); // Set the first file as active by default
    }
  }, [designFiles, activeDesignFileId, dispatch]);

  // Function to add a random file
  const handleAddRandomFile = (filePath) => {
    const newRandomFile = {
      id: `${filePath}-${Date.now()}`,
      name: filePath.split("/").pop(), // Just the file name
      content: "", // Fetch the content as needed
      path: filePath, // Store the path
    };
    setRandomFiles((prev) => [...prev, newRandomFile]);
    dispatch(setActiveDesignFile(newRandomFile.id)); // Set it as the active file
  };
  return (
    <div className="w-1/2 flex flex-col border border-primary p-0.5 bg-background text-text dark:bg-background dark:text-text">
      {/* Tab header */}
      <div className="flex bg-primary p-0.5 pl-1 pr-4 -mt-0.5 overflow-auto custom-scrollbar dark:bg-primary">
        <div className="flex">
          {/* Render the design file tabs */}
          {designFiles.map((file) => (
            <div
              key={file.id}
              className={`flex items-center px-3 rounded mr-2 cursor-pointer ${
                activeDesignFileId === file.id
                  ? "bg-background text-primary dark:bg-background dark:text-primary" // Active tab
                  : "bg-secondary text-text hover:bg-accent dark:bg-secondary dark:text-text dark:hover:bg-accent" // Inactive tab with hover
              }`}
              onClick={() => dispatch(setActiveDesignFile(file.id))}
              title={"Path of file..."} // Show path on hover
            >
              <span className="mr-2">{file.name}</span>
              <FaTimes
                className="text-secondary hover:text-accent dark:text-secondary dark:hover:text-accent"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseDesignFile(file.id);
                }}
              />
            </div>
          ))}
          {randomFiles.map((file) => (
            <div
              key={file.id}
              className={`flex items-center px-3 rounded mr-2 cursor-pointer ${
                activeDesignFileId === file.id
                  ? "bg-background text-primary dark:bg-background dark:text-primary" // Active tab
                  : "bg-secondary text-text hover:bg-accent dark:bg-secondary dark:text-text dark:hover:bg-accent" // Inactive tab with hover
              }`}
              onClick={() => dispatch(setActiveDesignFile(file.id))}
              title={file.path} // Show path on hover
            >
              <span className="mr-2">{file.name}</span>
              <FaTimes
                className="text-secondary hover:text-accent dark:text-secondary dark:hover:text-accent"
                onClick={(e) => {
                  e.stopPropagation();
                  setRandomFiles((prev) =>
                    prev.filter((f) => f.id !== file.id)
                  );
                }}
              />
            </div>
          ))}
          <button
            className="ml-2 text-text hover:text-accent dark:text-text dark:hover:text-accent"
            onClick={() => setIsNewDesignFileModalOpen(true)}
          >
            <FaPlus />
          </button>
          <NewFileModal
            isOpen={isNewDesignFileModalOpen}
            onClose={() => setIsNewDesignFileModalOpen(false)}
            fileType="design"
          />
        </div>
      </div>

      {/* Editor Container */}
      <div className="flex-1 overflow-hidden border border-primary bg-background text-text dark:border-primary dark:bg-background dark:text-text">
        {designFiles.map((file) => (
          <div
            key={file.id}
            className={activeDesignFileId === file.id ? "" : "hidden"}
          >
            <MonacoEditor
              file={file}
              handleDesignFileContentChange={handleDesignFileContentChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignEditor;

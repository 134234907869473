import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import useDebouncedResizeObserver from "./DebouncedResizeObserver";
import axios from "axios";
import {
  FaFolder,
  FaFileAlt,
  FaImage,
  FaFileCode,
  FaFilePdf,
  FaFileArchive,
  FaTimes,
  FaChevronRight,
  FaSearch,
  FaSync,
} from "react-icons/fa";
import { IoIosReturnLeft } from "react-icons/io";
import { GrReturn } from "react-icons/gr";
import { MdOutlineDeleteForever } from "react-icons/md";
import { TbCut } from "react-icons/tb";
import { LiaPasteSolid } from "react-icons/lia";
import { LiaTrashAlt } from "react-icons/lia";
import { TiArrowSync } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import {
  closeFileManager,
  selectIsFileManagerOpen,
  toggleFileManager,
} from "../features/toggling/fileManagerSlice";
import {
  PiFile,
  PiFileLight,
  PiFileLock,
  PiFilePlus,
  PiFiles,
  PiFolderPlus,
} from "react-icons/pi";
import { closeSettings } from "../features/toggling/settingsSlice";
import {
  addProject,
  addTestbenchFileToProject,
  moveFile,
  setActiveProject,
} from "../features/editorSlice";
import { selectShowExtraInfo } from "../features/showExtraInfoSlice";
import {
  selectShowHidden,
  toggleShowHidden,
} from "../features/showHiddenFiles";

const FileManager = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsFileManagerOpen);
  const [currentPath, setCurrentPath] = useState("/home/edacloud");
  const [parentPath, setParentPath] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isCopyOperationStarted, setIsCopyOperationStarted] = useState(false);
  const [copyFiles, setCopyFiles] = useState([]);
  const [isMoveOperationStarted, setIsMoveOperationStarted] = useState(false);
  const [moveFiles, setMoveFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [fileType, setFileType] = useState("file");
  const [newFileName, setNewFileName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isRenaming, setIsRenaming] = useState(false);
  const [renamingFile, setRenamingFile] = useState(null);
  const [newName, setNewName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const renameInputRef = useRef(null);

  const showHidden = useSelector(selectShowHidden);

  // console.log("selected", selectedFiles);

  const handleToggleHiddenFiles = () => {
    dispatch(toggleShowHidden());
  };
  const showExtraInfo = useSelector(selectShowExtraInfo);

  const handleIconClick = (type) => {
    setFileType(type);
    setIsModalOpen(true);
  };

  const [contextMenu, setContextMenu] = useState({
    show: false,
    x: 0,
    y: 0,
    item: null,
  });

  const fetchFiles = async (path = "", showHidden) => {
    try {
      const response = await axios.get(`http://localhost:3001/get-files`, {
        params: { path, showHidden: showHidden },
      });
      const data = response.data;
      setFiles(data.files);
      // console.log(data.files)
      setCurrentPath(data.currentPath);
      setParentPath(data.parentPath);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleCreateFileFolder = async () => {
    if (!newFileName) {
      alert("Please enter a name for the new file or folder.");
      return;
    }

    try {
      const filePath = `${currentPath}/${newFileName}`;
      const response = await axios.post("http://localhost:3001/create", {
        filePath,
        fileType,
        content: "",
      });

      if (response.data.message) {
        alert(response.data.message);
        setNewFileName("");
        fetchFiles(currentPath);
      }
    } catch (error) {
      alert(
        error.response?.data?.error || "Error during file/folder creation."
      );
    } finally {
      setIsModalOpen(false);
    }
  };

  // Perform DELETE operation for selected files/folders
  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      try {
        console.log("p", selectedFiles);
        const paths = selectedFiles.map((file) => file.fullPath);
        console.log("pp", paths);
        const response = await axios.post(`http://localhost:3001/delete`, {
          paths,
        });

        if (response.status === 200) {
          alert("Selected items deleted successfully!");
          setSelectedFiles([]);
          fetchFiles(currentPath);
        } else {
          alert(`Error deleting: ${response.error}`);
        }
      } catch (error) {
        console.error("Delete error:", error);
      }
    }
  };

  const handleRenameFile = async (file) => {
    setIsRenaming(true);
    setRenamingFile(file);
    setNewName(file.name);
  };

  // const handleRename = async () => {
  //   console.log(newName)
  //   if (newName && newName !== renamingFile.name) {
  //     try {
  //       await axios.put(`http://localhost:3001/rename`, {
  //         oldPath: `${currentPath}/${renamingFile.name}`,
  //         newPath: `${currentPath}/${newName}`,
  //       });
  //       fetchFiles(currentPath);
  //     } catch (error) {
  //       console.error("Error renaming file:", error);
  //       alert("Failed to rename file");
  //     }
  //   }
  //   setIsRenaming(false);
  //   setRenamingFile(null);
  //   setNewName("");
  // };

  // Function to rename the selected file/folder
  const handleRename = async (path) => {
    if (newName && newName !== renamingFile.name) {
      try {
        console.log("RAJA", { oldPath: path, newName });
        const response = await axios.post("http://localhost:3001/rename", {
          oldPath: path,
          newName,
        });

        if (response.status === 200) {
          alert("File/Folder renamed successfully!");
          fetchFiles(currentPath); // Refresh the file list
        } else {
          alert(`Rename failed: ${response.data.error}`);
        }
      } catch (error) {
        console.error("Rename error:", error);
        alert("An error occurred during rename operation.");
      }
    }
    setIsRenaming(false);
    setRenamingFile(null);
    setNewName("");
  };

  const handleDownloadFile = async (file) => {
    try {
      const response = await axios.get(`http://localhost:3001/download`, {
        params: { path: `${currentPath}/${file.name}` },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download file");
    }
  };

  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true); // Start the loading state

      // Fetch the files from the current path
      await fetchFiles(currentPath); // Wait until the fetch completes
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error during refresh:", error); // Handle any errors
      alert("Failed to refresh. Please try again.");
    } finally {
      setIsRefreshing(false); // End the loading state
    }
  };

  useEffect(() => {
    const ws = new WebSocket("ws://localhost:3001");
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "fileCreated" && data.path === currentPath) {
        fetchFiles(currentPath, showHidden);
      }
    };
    return () => ws.close();
  }, [currentPath, showHidden]);

  const getFileIcon = (file) => {
    if (file.type === "folder") return FaFolder;
    const iconMap = {
      pdf: FaFilePdf,
      jpg: FaImage,
      jpeg: FaImage,
      png: FaImage,
      gif: FaImage,
      bmp: FaImage,
      py: FaFileCode,
      js: FaFileCode,
      java: FaFileCode,
      cpp: FaFileCode,
      html: FaFileCode,
      css: FaFileCode,
      zip: FaFileArchive,
      rar: FaFileArchive,
      "7z": FaFileArchive,
      tar: FaFileArchive,
      gz: FaFileArchive,
    };
    return iconMap[file.extension?.toLowerCase()] || FaFileAlt;
  };

  const handleContextMenu = (e, file) => {
    e.preventDefault();
    setContextMenu({
      show: true,
      x: e.pageX,
      y: e.pageY,
      item: file,
    });
    setSelectedFiles((prev) =>
      prev.includes(file) ? prev.filter((f) => f !== file) : [...prev, file]
    );
  };

  const handleFileSelect = (file, e) => {
    if (e.ctrlKey || e.metaKey) {
      setSelectedFiles((prev) =>
        prev.includes(file) ? prev.filter((f) => f !== file) : [...prev, file]
      );
    } else {
      setSelectedFiles([file]);
    }
  };

  const handleCopy = () => {
    setIsCopyOperationStarted(true);
    if (selectedFiles.length === 0) {
      alert("No files or folders selected to copy.");
      return;
    }
    setCopyFiles(selectedFiles); // Hold the files/folders for copying
    alert("Files/folders copied! Now you can paste them.");
  };
  const handleCut = () => {
    setIsMoveOperationStarted(true);
    if (selectedFiles.length === 0) {
      alert("No files or folders selected to move.");
      return;
    }
    setMoveFiles(selectedFiles); // Hold the files/folders for copying
    alert("Files/folders cutted! Now you can paste them.");
  };

  // Perform paste operation by copying the files/folders to the current path
  const handleCopyPaste = async () => {
    if (copyFiles.length === 0) {
      alert("Nothing to paste.");
      return;
    }

    try {
      const copies = copyFiles.map((file) => ({
        oldPath: file.fullPath,
        newPath: `${currentPath}/${file.name}`,
      }));

      console.log("Copy payload:", copies);

      const response = await axios.post("http://localhost:3001/copy", {
        copies,
      });

      if (response.status === 200) {
        alert("Files/folders pasted successfully!");
        setCopyFiles([]); // Clear held copy information after paste
        fetchFiles(currentPath);
      } else {
        alert(`Paste failed: ${response.data.error}`);
      }
    } catch (error) {
      console.error("Paste error:", error);
      alert("An error occurred during the paste operation.");
    } finally {
      setIsCopyOperationStarted(false);
    }
  };

  // Perform MOVE operation for selected files/folders
  const handleCutPaste = async () => {
    try {
      console.log("m", moveFiles);
      const moves = moveFiles.map((file) => ({
        oldPath: file.fullPath,
        newPath: `${currentPath}/${file.name}`,
      }));
      console.log("mm", moves);

      const response = await axios.post("http://localhost:3001/move", {
        moves,
      });

      if (response.status === 200) {
        alert("Files/folders moved successfully!");
        setMoveFiles([]);
        fetchFiles(currentPath);
      } else {
        alert(`Error moving: ${response.data.error}`);
      }
    } catch (error) {
      console.error("Move error:", error);
    } finally {
      setIsMoveOperationStarted(false);
    }
  };

  const handleFolderDoubleClick = (file) => {
    if (file.type === "folder") {
      let newPath;
      if (currentPath === "") {
        newPath = file.name;
      } else {
        const basePath = currentPath.endsWith("/")
          ? currentPath.slice(0, -1)
          : currentPath;
        newPath = `${basePath}/${file.name}`;
      }

      if (newPath === currentPath) {
        console.log("Already at the selected folder:", newPath);
        return;
      }

      console.log("Navigating to:", newPath);
      setCurrentPath(newPath);
    }
  };

  const fetchFileContent = async (filePath) => {
    try {
      const response = await axios.get(
        `http://localhost:3001/view-file-contents?path=${encodeURIComponent(
          filePath
        )}`
      );
      if (!response.status === 200) {
        throw new Error("Failed to fetch file content");
      }
      const data = await response.data;
      return data.contents;
    } catch (error) {
      console.error("Error fetching file content:", error);
      return ""; // Return empty string or handle error appropriately
    }
  };

  // const handleFileContentChange = (fileId, content) => {
  //   // Update the content of the specific file
  //   const updatedFiles = testbenchFiles.map((file) =>
  //     file.id === fileId ? { ...file, content } : file
  //   );
  //   dispatch(updateTestbenchFiles({ projectId: activeProjectId, testbenchFiles: updatedFiles }));
  // };

  const handleFileDoubleClick = async (file) => {
    // Fetch file contents (you need to implement this logic)
    const fileContent = await fetchFileContent(file.fullPath);
    // console.log(fileContent);
    const projectId = `${"project1"}-${Date.now()}`;
    dispatch(addProject({ name: "project1" }));
    dispatch(setActiveProject(projectId));

    dispatch(
      addTestbenchFileToProject({
        projectId,
        file: {
          id: `${file.name}-${Date.now()}`,
          name: file.name,
          content: fileContent,
          language: file.extension,
        },
      })
    );
    // setActiveTestbenchFileId(file.id);
    // Assume you have a function to update the editor content
    // handleFileContentChange(file.id, fileContent);
  };

  const goBack = () => {
    if (parentPath !== null) {
      setCurrentPath(parentPath);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    droppedFiles.forEach(async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("path", currentPath);
      try {
        await axios.post("http://localhost:3001/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        fetchFiles(currentPath);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    });
  };

  const fileManagerRef = useRef(null);
  const resizeObserver = useDebouncedResizeObserver((entries) => {
    // Handle resize here if needed
  });

  useEffect(() => {
    if (fileManagerRef.current && resizeObserver) {
      resizeObserver.observe(fileManagerRef.current);
    }

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        dispatch(toggleFileManager());
        dispatch(closeSettings());
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [resizeObserver, dispatch, isOpen]);

  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu.show) {
        setContextMenu({ show: false, x: 0, y: 0, item: null });
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [contextMenu.show]);

  const handleDragStart = (e, file) => {
    e.dataTransfer.setData("file", JSON.stringify(file));
  };

  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Close rename input if clicked outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        renameInputRef.current &&
        !renameInputRef.current.contains(e.target)
      ) {
        setIsRenaming(false); // Close the input
        setSelectedFiles([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    fetchFiles(currentPath, showHidden);
  }, [currentPath, showHidden]);

  if (!isOpen) return null;

  return (
    <>
      {isOpen && (
        <motion.div
          ref={fileManagerRef}
          initial={{ width: 0 }}
          animate={{ width: isOpen ? "20%" : 0 }}
          transition={{ duration: 0.5, ease: [0.42, 0, 0.58, 1] }}
          className="h-full w-full bg-blue-50 border-y-2 border-l border-blue-900 text-sm  shadow-xl"
        >
          <div className="bg-blue-200 flex border-b border-blue-900 items-center justify-between p-2">
            <h2 className="text-md font-semibold text-start">File Manager</h2>
            <button
              onClick={() => dispatch(closeFileManager())}
              className="p-1 hover:bg-blue-300 rounded-full transition-colors"
            >
              <FaTimes className="w-4 h-4" />
            </button>
          </div>

          <div className="bg-blue-200 border-b border-blue-900 flex items-center justify-between px-2 py-1">
            <div className="flex-1 flex items-center space-x-2">
              <FaSearch className="text-gray-500 w-4 h-4" />
              <input
                type="text"
                placeholder="Search files..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="bg-transparent border border-blue-500 rounded-xl pl-3 focus:ring-1  flex-1 outline-none"
              />
            </div>
          </div>

          <div className="border border-gray-300 dark:border-gray-700 p-1 bg-gray-50 dark:bg-black flex items-center justify-between text-sm gap-3">
            <button
              onClick={goBack}
              disabled={!parentPath}
              className={`flex items-center justify-center p-[2px] rounded-md transition-colors duration-200 ease-in-out ${
                parentPath
                  ? "bg-blue-500 text-white hover:bg-blue-600 focus:ring-2 focus:ring-blue-400 focus:outline-none"
                  : "bg-blue-300 text-blue-500 cursor-not-allowed opacity-50"
              }`}
              aria-label="Go Back"
            >
              <GrReturn
                className={`w-4 h-4 ${
                  parentPath ? "text-white" : "text-blue-500"
                } transition-colors duration-200`}
              />
            </button>
            <PiFolderPlus
              className="w-5 h-5 cursor-pointer text-blue-500 hover:text-blue-600 transition-colors duration-200 ease-in-out"
              onClick={() => handleIconClick("folder")}
              title="New Folder"
            />

            <PiFilePlus
              className="w-5 h-5 cursor-pointer text-green-500 hover:text-green-600 transition-colors duration-200 ease-in-out"
              onClick={() => handleIconClick("file")}
              title="New File"
            />

            <TbCut
              className={`w-5 h-5 cursor-pointer text-red-500 hover:text-red-600 transition-colors duration-200 ease-in-out ${
                selectedFiles && selectedFiles.length > 0 ? "" : "opacity-40"
              }`}
              onClick={() => handleCut()}
              title="Cut"
            />

            <PiFiles
              className={`w-5 h-5 cursor-pointer text-yellow-500 hover:text-yellow-600 transition-colors duration-200 ease-in-out ${
                selectedFiles && selectedFiles.length > 0 ? "" : "opacity-40"
              }`}
              onClick={() => handleCopy()}
              title="Copy"
            />

            <LiaPasteSolid
              className={`w-5 h-5 cursor-pointer text-green-500 hover:text-green-600 transition-colors duration-200 ease-in-out ${
                isCopyOperationStarted || isMoveOperationStarted
                  ? ""
                  : "opacity-40"
              }`}
              onClick={() => {
                isCopyOperationStarted && handleCopyPaste();
                isMoveOperationStarted && handleCutPaste();
              }}
              title="Paste"
            />

            <MdOutlineDeleteForever
              className={`w-5 h-5 cursor-pointer text-red-500 hover:text-red-600 transition-colors duration-200 ease-in-out ${
                selectedFiles && selectedFiles.length > 0 ? "" : "opacity-40"
              }`}
              onClick={() => handleDelete()}
              title="Delete selected file/folder"
            />

            <button
              className="text-green-500 hover:text-green-600 transition-colors duration-200 ease-in-out"
              onClick={handleRefresh}
              disabled={isRefreshing}
              title="Refresh folder"
            >
              {isRefreshing ? (
                <TiArrowSync className="w-5 h-5 animate-spin cursor-pointer bg-opacity-50" />
              ) : (
                <TiArrowSync className="w-5 h-5" />
              )}
            </button>

            <PiFileLock
              className={`w-5 h-5 cursor-pointer text-gray-500 hover:text-gray-600 transition-colors duration-200 ease-in-out ${
                showHidden ? "rounded-sm bg-gray-200 dark:bg-gray-700" : ""
              }`}
              onClick={() => handleToggleHiddenFiles()}
              title="Show hidden (dot) files/folders"
            />

            {/* Modal */}
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-80 space-y-4">
                  <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                    Create New {fileType === "file" ? "File" : "Folder"}
                  </h2>
                  <input
                    type="text"
                    value={newFileName}
                    onChange={(e) => setNewFileName(e.target.value)}
                    placeholder={`Enter ${fileType} name`}
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring focus:ring-blue-100 dark:focus:ring-blue-300 focus:border-blue-300 dark:focus:border-blue-600"
                  />
                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-300 rounded-md hover:bg-gray-400 dark:hover:bg-gray-600"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleCreateFileFolder}
                      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="py-1 bg-blue-50 border-y border-blue-900 flex items-center px-2 overflow-x-auto custom-scrollbar"
            title="Current directory, all new folder and new files are created in this directory"
          >
            <div className="flex items-center text-sm">
              <button
                className="hover:text-blue-600 px-1"
                onClick={() => setCurrentPath("/home/edacloud")}
                onDoubleClick={() =>
                  handleFolderDoubleClick({ type: "folder", name: "/home" })
                }
              >
                Home
              </button>
              <FaChevronRight className="w-2 h-2 text-gray-400" />
            </div>

            {currentPath
              .replace("/home", "")
              .split("/")
              .filter(Boolean)
              .map((folder, index, array) => {
                const folderPath = `/home/${array
                  .slice(0, index + 1)
                  .join("/")}`;

                return (
                  <div
                    key={index}
                    className="flex items-center text-xs whitespace-nowrap"
                  >
                    <button
                      className="hover:text-blue-600 px-1"
                      onClick={() => setCurrentPath(folderPath)}
                      onDoubleClick={() =>
                        handleFolderDoubleClick({
                          type: "folder",
                          name: folderPath,
                        })
                      }
                    >
                      {folder}
                    </button>
                    {index < array.length - 1 && (
                      <FaChevronRight className="w-2 h-2 text-gray-400" />
                    )}
                  </div>
                );
              })}
          </div>

          <div className="h-full w-full flex flex-col  ">
            <div
              className="flex-1 pb-56 text-xs overflow-auto custom-scrollbar-filemanager"
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              <table className="table-auto w-full  ">
                {/* Table Header */}
                <thead>
                  <tr className="bg-gray-100 border-b border-gray-300">
                    <th className="px-2 py-2 text-left  font-semibold whitespace-nowrap">
                      Name
                    </th>
                    <th className="px-4 py-2 text-left  font-semibold whitespace-nowrap">
                      Size
                    </th>
                    <th className="px-4 py-2 text-left  font-semibold whitespace-nowrap">
                      Modified Date
                    </th>
                    {showExtraInfo && (
                      <>
                        <th className="px-4 py-2 text-left  font-semibold whitespace-nowrap">
                          Owner
                        </th>
                        <th className="px-4 py-2 text-left  font-semibold whitespace-nowrap">
                          Group
                        </th>
                        <th className="px-4 py-2 text-left  font-semibold whitespace-nowrap">
                          Permissions
                        </th>
                      </>
                    )}
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {filteredFiles.map((file, idx) => {
                    const Icon = getFileIcon(file);
                    return (
                      <tr
                        key={idx}
                        className={`hover:bg-blue-100 ${
                          selectedFiles.includes(file) ? "bg-blue-200" : ""
                        }`}
                        draggable
                        onDragStart={(e) => handleDragStart(e, file)}
                        onClick={(e) => handleFileSelect(file, e)}
                        onContextMenu={(e) => handleContextMenu(e, file)}
                        onDoubleClick={() => {
                          file.isDirectory && handleFolderDoubleClick(file);
                          handleFileDoubleClick(file);
                        }}
                      >
                        {/* Name Column */}
                        <td className="px-2 py-2 flex items-center space-x-2 whitespace-nowrap">
                          <Icon
                            className={`${
                              file.type === "folder"
                                ? "text-blue-500"
                                : "text-gray-500"
                            } w-4 h-4`}
                          />
                          {isRenaming && renamingFile === file ? (
                            <input
                              ref={renameInputRef}
                              type="text"
                              value={newName}
                              onChange={(e) => setNewName(e.target.value)}
                              onKeyPress={(e) =>
                                e.key === "Enter" && handleRename(file.fullPath)
                              }
                              className="border border-blue-300 rounded px-1"
                              autoFocus
                            />
                          ) : (
                            <span className=" truncate">{file.name}</span>
                          )}
                        </td>

                        {/* Other Columns */}
                        <td className="px-4 py-2  text-gray-500 whitespace-nowrap">
                          {file.size}
                        </td>
                        <td className="px-4 py-2  text-gray-500 whitespace-nowrap">
                          {file.modifiedDate}
                        </td>
                        {showExtraInfo && (
                          <>
                            <td className="px-4 py-2  text-gray-500 whitespace-nowrap">
                              {file.owner}
                            </td>
                            <td className="px-4 py-2  text-gray-500 whitespace-nowrap">
                              {file.group}
                            </td>
                            <td className="px-4 py-2  text-gray-500 whitespace-nowrap">
                              {file.permissions}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Context Menu */}
            {contextMenu.show && (
              <div
                className="fixed bg-white shadow-lg rounded-lg py-1 w-48  z-50"
                style={{ top: contextMenu.y, left: contextMenu.x }}
              >
                <button
                  className="w-full text-left px-4 py-2 hover:bg-blue-100"
                  onClick={() => handleFolderDoubleClick(contextMenu.item)}
                >
                  Open
                </button>
                <button
                  className="w-full text-left px-4 py-2 hover:bg-blue-100"
                  onClick={() => handleDownloadFile(contextMenu.item)}
                >
                  Download
                </button>
                <button
                  className="w-full text-left px-4 py-2 hover:bg-blue-100"
                  onClick={() => handleRenameFile(contextMenu.item)}
                >
                  Rename
                </button>
                <button
                  className="w-full text-left px-4 py-2 hover:bg-blue-100"
                  onClick={() => handleDelete(selectedFiles)}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </>
  );
};

export default FileManager;

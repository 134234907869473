import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEY = "modeState"; 

const saveStateToLocalStorage = (state) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!savedState) return undefined;

  return JSON.parse(savedState);
};

const modeSlice = createSlice({
  name: "mode",
  initialState: loadStateFromLocalStorage() || { mode: "gui" },
  reducers: {
    switchMode: (state) => {
      state.mode = state.mode === "gui" ? "cli" : "gui"; 
      saveStateToLocalStorage(state); 
    },
    setMode: (state, action) => {
      state.mode = action.payload; 
      saveStateToLocalStorage(state); 
    },
  },
});

export const { switchMode, setMode } = modeSlice.actions;

export const selectMode = (state) => state.mode.mode;

export default modeSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { examples } from '../data/examples'; 

const initialState = {
  examples, 
  selectedExample: null, 
};

export const examplesSlice = createSlice({
  name: 'examples',
  initialState,
  reducers: {
    loadExample: (state, action) => {
      const example = state.examples.find(
        (example) => example.name === action.payload
      );
      state.selectedExample = example || null; 
    },
    clearSelectedExample: (state) => {
      state.selectedExample = null;
    },
  },
});

export const { loadExample, clearSelectedExample } = examplesSlice.actions;

export const selectSelectedExample = (state) => state.examples.selectedExample;

export const selectAllExamples = (state) => state.examples.examples;

export default examplesSlice.reducer;

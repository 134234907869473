import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEY = "notificationsCount";

const saveStateToLocalStorage = (state) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  return savedState ? JSON.parse(savedState) : 2; 
};


const notificationsSlice = createSlice({
  name: "notifications",
  initialState: { count: loadStateFromLocalStorage() }, 
  reducers: {
    setNotifications: (state, action) => {
      state.count = action.payload;
      saveStateToLocalStorage(state.count); 
    },
    incrementNotifications: (state) => {
      state.count += 1;
      saveStateToLocalStorage(state.count); 
    },
    resetNotifications: (state) => {
      state.count = 0;
      saveStateToLocalStorage(state.count); 
    },
  },
});


export const { setNotifications, incrementNotifications, resetNotifications } =
  notificationsSlice.actions;
export const selectNotifications = (state) => state.notifications.count;


export default notificationsSlice.reducer;

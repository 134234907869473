import { motion } from "framer-motion";
import { FaFolder, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "./UIcomponents";
import {
  closeExamples,
  selectIsExamplesOpen,
  toggleExamples,
} from "../features/toggling/toggleExamplesSlice";
import { loadExampleIntoProject } from "../features/editorSlice";
import { useEffect, useRef } from "react";
import { examples } from "../data/examples";
import { closeFileManager } from "../features/toggling/fileManagerSlice";
import DebouncedResizeObserver from "./DebouncedResizeObserver";

const Examples = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsExamplesOpen);
  const activeProjectId = useSelector((state) => state.editor.activeProjectId);
  const projects = useSelector((state) => state.editor.projects);
  const handleLoadExample = (example) => {
    const targetProjectId = activeProjectId || projects[0].id; // Default to the first project if none is active
    console.log("tt", targetProjectId, examples);
    console.log("tcvxvt", targetProjectId, example);
    dispatch(loadExampleIntoProject({ example, targetProjectId }));
  };

  const examplesRef = useRef(null);
  const resizeObserver = DebouncedResizeObserver((entries) => {
    // Handle resize here if needed
  });

  useEffect(() => {
    if (examplesRef.current && resizeObserver) {
      resizeObserver.observe(examplesRef.current);
    }

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        dispatch(toggleExamples());
        dispatch(closeFileManager());
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [resizeObserver, dispatch, isOpen]);

  if (!isOpen) return null; // Do not render if the modal is closed

  return (
    <>
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: isOpen ? "20%" : 0 }}
        transition={{ duration: 0.5, ease: [0.42, 0, 0.58, 1] }}
        className="h-full w-full bg-background border-y-2 border-l border-primary z-5 shadow-xl overflow-hidden dark:bg-background dark:border-primary"
      >
        {/* Header */}
        <div className="bg-secondary flex border-b border-primary items-center justify-between p-2 dark:bg-secondary dark:border-primary">
          <h2 className="text-md font-semibold text-text">Examples</h2>
          <button
            onClick={() => dispatch(closeExamples())}
            className="p-1 hover:bg-accent rounded-full transition-colors dark:hover:bg-accent"
          >
            <FaTimes className="w-4 h-4 text-text dark:text-text" />
          </button>
        </div>

        {/* Content */}
        <div className="p-2 overflow-hidden h-full">
          <div className="space-y-2">
            {examples.map((example) => (
              <Button
                key={example.name}
                className={`w-full flex items-center justify-start ${"hover:bg-secondary text-text dark:hover:bg-secondary dark:text-text"}`}
                onClick={() => handleLoadExample(example)}
              >
                <FaFolder className="mr-2 text-text dark:text-text" />
                {example.name}
              </Button>
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Examples;

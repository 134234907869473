import { createSlice } from "@reduxjs/toolkit";

// Default project with example files.
const defaultProject = {
  id: "untitled_project",
  name: "Untitled Project",
  testbenchFiles: [
    {
      id: "example_tb",
      name: "tb.sv",
      content: "// Write testbench code here or load examples from sidebar...",
      language: "systemverilog",
    },
  ],
  designFiles: [
    {
      id: "example_dut",
      name: "dut.sv",
      content: "// Write design code here",
      language: "systemverilog",
    },
  ],
};

// Initial state with the default project.
const initialState = {
  projects: JSON.parse(localStorage.getItem("projects")) || [defaultProject],
  activeProjectId:
    (JSON.parse(localStorage.getItem("projects")) &&
      JSON.parse(localStorage.getItem("projects"))[0].id) ||
    "untitled_project",
  activeTestbenchFileId: "example_tb",
  activeDesignFileId: "example_dut",
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    // Add a new project with validation to prevent duplicate names.
    addProject: (state, action) => {
      const { name } = action.payload;

      if (state.projects.some((p) => p.name === name)) {
        alert("Project with this name already exists!");
        return;
      }

      const newProject = {
        id: `${name}-${Date.now()}`,
        name,
        testbenchFiles: defaultProject.testbenchFiles,
        designFiles: defaultProject.designFiles,
      };

      state.projects.push(newProject);
      state.activeProjectId = newProject.id;
      localStorage.setItem("projects", JSON.stringify(state.projects));
    },

    setActiveProject: (state, action) => {
      state.activeProjectId = action.payload;
    },
    setActiveTestbenchFile: (state, action) => {
      state.activeTestbenchFileId = action.payload;
    },
    setActiveDesignFile: (state, action) => {
      state.activeDesignFileId = action.payload;
    },

    // Rename an existing project with duplicate name validation.
    renameProject: (state, action) => {
      const { projectId, newName } = action.payload;
      const project = state.projects.find((p) => p.id === projectId);

      if (state.projects.some((p) => p.name === newName)) {
        alert("Project with this name already exists!");
        return;
      }

      if (project) {
        project.name = newName;
        localStorage.setItem("projects", JSON.stringify(state.projects));
      }
    },

    renameFile: (state, action) => {
      const { projectId, fileId, fileType, newName } = action.payload;
      const project = state.projects.find((p) => p.id === projectId);
      if (project) {
        const file =
          fileType === "testbench"
            ? project.testbenchFiles.find((f) => f.id === fileId)
            : project.designFiles.find((f) => f.id === fileId);

        if (file) {
          file.name = newName;
          localStorage.setItem("projects", JSON.stringify(state.projects));
        }
      }
    },

    // Delete a project by ID.
    deleteProject: (state, action) => {
      const projectId = action.payload;
      state.projects = state.projects.filter((p) => p.id !== projectId);

      if (state.activeProjectId === projectId) {
        state.activeProjectId = null;
      }

      localStorage.setItem("projects", JSON.stringify(state.projects));
    },

    addTestbenchFileToProject: (state, action) => {
      const { projectId, file } = action.payload;
      const project = state.projects.find((p) => p.id === projectId);

      if (project) {
        const newFile = {
          id: `${file.name}-${Date.now()}`,
          name: file.name,
          content: file.content || "",
          language: file.language || "systemverilog",
        };
        project.testbenchFiles.push(newFile);

        // Set the new file as the active testbench file
        state.activeTestbenchFileId = newFile.id;

        localStorage.setItem("projects", JSON.stringify(state.projects));
      }
    },

    addDesignFileToProject: (state, action) => {
      const { projectId, file } = action.payload;
      const project = state.projects.find((p) => p.id === projectId);

      if (project) {
        const newFile = {
          id: `${file.name}-${Date.now()}`,
          name: file.name,
          content: file.content || "",
          language: file.language || "systemverilog",
        };
        project.designFiles.push(newFile);

        // Set the new file as the active design file
        state.activeDesignFileId = newFile.id;

        localStorage.setItem("projects", JSON.stringify(state.projects));
      }
    },

    // Move a file from one project to another.
    moveFile: (state, action) => {
      const { sourceProjectId, targetProjectId, fileId, fileType } =
        action.payload;
      const sourceProject = state.projects.find(
        (p) => p.id === sourceProjectId
      );
      const targetProject = state.projects.find(
        (p) => p.id === targetProjectId
      );

      if (sourceProject && targetProject) {
        let fileToMove;

        if (fileType === "testbench") {
          fileToMove = sourceProject.testbenchFiles.find(
            (f) => f.id === fileId
          );
          sourceProject.testbenchFiles = sourceProject.testbenchFiles.filter(
            (f) => f.id !== fileId
          );
        } else if (fileType === "design") {
          fileToMove = sourceProject.designFiles.find((f) => f.id === fileId);
          sourceProject.designFiles = sourceProject.designFiles.filter(
            (f) => f.id !== fileId
          );
        }

        if (fileToMove) {
          if (fileType === "testbench") {
            targetProject.testbenchFiles.push(fileToMove);
          } else if (fileType === "design") {
            targetProject.designFiles.push(fileToMove);
          }
        }

        localStorage.setItem("projects", JSON.stringify(state.projects));
      }
    },

    // Update the content of a specific file in a project.
    updateFileContent: (state, action) => {
      const { projectId, fileId, fileType, newContent } = action.payload;
      const project = state.projects.find((p) => p.id === projectId);

      if (project) {
        let file;

        if (fileType === "testbench") {
          file = project.testbenchFiles.find((f) => f.id === fileId);
        } else if (fileType === "design") {
          file = project.designFiles.find((f) => f.id === fileId);
        }

        if (file) {
          file.content = newContent;
          localStorage.setItem("projects", JSON.stringify(state.projects));
        }
      }
    },

    // Load an example project into an existing project.
    loadExampleIntoProject: (state, action) => {
      const { example, targetProjectId } = action.payload;
      const project = state.projects.find((p) => p.id === targetProjectId);

      if (project) {
        project.testbenchFiles.push(...example.testbenchFiles);
        project.designFiles.push(...example.designFiles);
        localStorage.setItem("projects", JSON.stringify(state.projects));
      }
    },
  },
});

export const selectActiveTestbenchFileId = (state) =>
  state.editor.activeTestbenchFileId;

export const selectActiveDesignFileId = (state) =>
  state.editor.activeDesignFileId;

export const selectActiveProject = (state) =>
  state.editor.projects.find((p) => p.id === state.editor.activeProjectId);

export const {
  addProject,
  renameProject,
  renameFile,
  deleteProject,
  setActiveProject,
  addTestbenchFileToProject,
  addDesignFileToProject,
  setActiveTestbenchFile,
  setActiveDesignFile,
  moveFile,
  updateFileContent,
  loadExampleIntoProject,
} = editorSlice.actions;

export default editorSlice.reducer;

import React from "react";
import { useSelector } from "react-redux";
import { selectOperationLogs } from "../features/edaLogsSlice";

const EDAlogs = () => {
  const logs = useSelector(selectOperationLogs);

  return (
    <div className="space-y-[1px]">
      {logs.map((log, index) => (
        <pre key={index} className="flex">
          <span className="text-text mr-2">[{log.timestamp}]</span>
          {log.type === "error" ? (
            <span className="text-red-500 ">{log.message}</span>
          ) : (
            <span className="text-text">{log.message}</span>
          )}
        </pre>
      ))}
      {logs.length === 0 && (
        <div className="text-text italic">No operations performed yet...</div>
      )}
    </div>
  );
};

export default EDAlogs;

import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef } from "react";
import {
  FaAppStore,
  FaChevronLeft,
  FaChevronRight,
  FaCloud,
  FaCode,
  FaCog,
  FaDesktop,
  FaSave,
  FaTerminal,
} from "react-icons/fa";
import { GoFileDirectoryFill } from "react-icons/go";
import { Button } from "./UIcomponents";
import useDebouncedResizeObserver from "./DebouncedResizeObserver";
import XpraWindow from "./XpraWindow";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSettings,
  toggleSettings,
} from "../features/toggling/settingsSlice";
import {
  closeFileManager,
  toggleFileManager,
} from "../features/toggling/fileManagerSlice";
import { toggleEDAcloud } from "../features/toggling/edaCloudSlice";
import {
  closeExamples,
  toggleExamples,
} from "../features/toggling/toggleExamplesSlice";
import { selectMode, switchMode } from "../features/toggling/modeSlice";
import { setStatusMessage } from "../features/statusSlice";
import {
  selectIsLeftSidebarCollapsed,
  setSidebarState,
  toggleSidebar,
} from "../features/sidebarSlice";

const Sidebar = ({ handleSave }) => {
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const resizeObserver = useDebouncedResizeObserver((entries) => {
    // Handle resize here if needed
  });
  const handleSetMessage = (msg) => {
    dispatch(setStatusMessage(msg));
  };
  const isLeftSidebarCollapsed = useSelector(selectIsLeftSidebarCollapsed);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };
  const handleSetSidebarState = useCallback(
    (state) => {
      dispatch(setSidebarState(state));
    },
    [dispatch]
  );

  const mode = useSelector(selectMode);
  const handleModeChange = () => {
    dispatch(switchMode());
    handleSetMessage(`Switched to ${mode === "gui" ? "CLI" : "GUI"} mode`);
  };
  useEffect(() => {
    if (sidebarRef.current && resizeObserver) {
      resizeObserver.observe(sidebarRef.current);
    }

    // Function to handle keydown events
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "b") {
        event.preventDefault();
        handleSetSidebarState((prev) => !prev); // Toggle sidebar
      }
    };

    // Add event listener for keydown
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [resizeObserver, handleSetSidebarState]);

  return (
    <motion.div
      ref={sidebarRef}
      className={`bg-background text-text dark:bg-background dark:text-text flex flex-col z-10 border-y-2 border-blue-900`}
      initial={false}
      animate={{ width: isLeftSidebarCollapsed ? 40 : 140 }}
    >
      <div className="p-1 flex flex-col items-center justify-center space-y-8 pt-2">
        <Button
          className={`w-full flex items-center ${
            isLeftSidebarCollapsed ? "justify-center" : "justify-start"
          } hover:bg-blue-400 dark:hover:bg-blue-700`}
          onClick={() => dispatch(toggleEDAcloud())}
          title="Toggle EDAcloud Ctrl+E"
        >
          <FaCloud className={isLeftSidebarCollapsed ? "m-0" : "mr-2"} />
          <span className={isLeftSidebarCollapsed ? "hidden" : ""}>
            EDAcloud
          </span>
        </Button>
        <Button
          className={`w-full flex items-center ${
            isLeftSidebarCollapsed ? "justify-center" : "justify-start"
          } hover:bg-blue-400 dark:hover:bg-blue-700`}
          onClick={handleModeChange}
          title="Editor/Terminal Switch"
        >
          {mode === "gui" ? (
            <FaTerminal className={isLeftSidebarCollapsed ? "m-0" : "mr-2"} />
          ) : (
            <FaDesktop className={isLeftSidebarCollapsed ? "m-0" : "mr-2"} />
          )}
          <span className={isLeftSidebarCollapsed ? "hidden" : ""}>
            {mode === "gui" ? "Terminal" : "Editor"}
          </span>
        </Button>
        <Button
          className={`w-full flex items-center ${
            isLeftSidebarCollapsed ? "justify-center" : "justify-start"
          } hover:bg-blue-400 dark:hover:bg-blue-700`}
          onClick={XpraWindow}
          title="Open GUI Apps"
        >
          <FaAppStore className={isLeftSidebarCollapsed ? "m-0" : "mr-2"} />
          <span className={isLeftSidebarCollapsed ? "hidden" : ""}>
            GUI Apps
          </span>
        </Button>
        <Button
          className={`w-full flex items-center ${
            isLeftSidebarCollapsed ? "justify-center" : "justify-start"
          } hover:bg-blue-400 dark:hover:bg-blue-700`}
          onClick={() => {
            handleSetSidebarState(true);
            dispatch(toggleFileManager());
            dispatch(closeSettings());
            dispatch(closeExamples());
          }}
          title="Toggle File Explorer Ctrl+F"
        >
          <GoFileDirectoryFill
            className={isLeftSidebarCollapsed ? "m-0" : "mr-2"}
          />
          <span className={isLeftSidebarCollapsed ? "hidden" : ""}>
            Explorer
          </span>
        </Button>
        <Button
          className={`w-full flex items-center ${
            isLeftSidebarCollapsed ? "justify-center" : "justify-start"
          } hover:bg-blue-400 dark:hover:bg-blue-700`}
          onClick={handleSave}
          title="Save"
        >
          <FaSave className={isLeftSidebarCollapsed ? "m-0" : "mr-2"} />
          <span className={isLeftSidebarCollapsed ? "hidden" : ""}>Save</span>
        </Button>
        <Button
          className={`w-full flex items-center ${
            isLeftSidebarCollapsed ? "justify-center" : "justify-start"
          } hover:bg-blue-400 dark:hover:bg-blue-700`}
          onClick={() => {
            handleSetSidebarState(true);
            dispatch(toggleExamples());
            dispatch(closeFileManager());
            dispatch(closeSettings());
          }}
          title="Show/Hide Examples Tab"
        >
          <FaCode className={isLeftSidebarCollapsed ? "m-0" : "mr-2"} />
          <span className={isLeftSidebarCollapsed ? "hidden" : ""}>
            Examples
          </span>
        </Button>
        <Button
          className={`w-full flex items-center ${
            isLeftSidebarCollapsed ? "justify-center" : "justify-start"
          } hover:bg-blue-400 dark:hover:bg-blue-700`}
          onClick={() => {
            handleSetSidebarState(true);
            dispatch(toggleSettings());
            dispatch(closeFileManager());
            dispatch(closeExamples());
          }}
          title="Toggle Settings Ctrl + S"
        >
          <FaCog className={isLeftSidebarCollapsed ? "m-0" : "mr-2"} />
          <span className={isLeftSidebarCollapsed ? "hidden" : ""}>
            Settings
          </span>
        </Button>
      </div>
      <Button
        className="mt-auto mb-2 mr-1 self-end rounded-full p-0 hover:bg-blue-500 dark:hover:bg-blue-600"
        onClick={() => handleToggleSidebar()}
        title="Toggle Sidebar Ctrl+B"
      >
        {isLeftSidebarCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
      </Button>
    </motion.div>
  );
};

export default Sidebar;

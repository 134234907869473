import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    isSettingsOpen: false,
  },
  reducers: {
    openSettings: (state) => {
      state.isSettingsOpen = true;
    },
    closeSettings: (state) => {
      state.isSettingsOpen = false;
    },
    toggleSettings: (state) => {
      state.isSettingsOpen = !state.isSettingsOpen;
    },
  },
});

export const { openSettings, closeSettings, toggleSettings } =
  settingsSlice.actions;

export const selectIsSettingsOpen = (state) => state.settings.isSettingsOpen;

export default settingsSlice.reducer;

import { useEffect, useRef } from 'react';

function DebouncedResizeObserver(callback, delay = 250) {
  const resizeObserver = useRef(null);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver(entries => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        callback(entries);
      }, delay);
    });

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [callback, delay]);

  return resizeObserver.current;
}

export default DebouncedResizeObserver;
import { createSlice } from "@reduxjs/toolkit";

const cursorPositionSlice = createSlice({
  name: "cursorPosition",
  initialState: { position: { line: 1, column: 1 } }, 
  reducers: {
    setCursorPosition: (state, action) => {
      state.position = action.payload; 
    },
    resetCursorPosition: (state) => {
      state.position = { line: 1, column: 1 }; 
    },
  },
});

export const { setCursorPosition, resetCursorPosition } =
  cursorPositionSlice.actions;
export const selectCursorPosition = (state) => state.cursorPosition.position;

export default cursorPositionSlice.reducer;

import React, { useEffect, useState } from "react";
import { FaCode, FaBug, FaSync, FaBell } from "react-icons/fa";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { useSelector } from "react-redux";
import { selectLanguage } from "../features/languageSlice";
import { selectStatusMessage } from "../features/statusSlice";
import { selectMode } from "../features/toggling/modeSlice";
import { selectNotifications } from "../features/notificationsSlice";
import { selectCursorPosition } from "../features/cursorPositionSlice";

const StatusBar = () => {
  const language = useSelector(selectLanguage);
  const mode = useSelector(selectMode);
  const notifications = useSelector(selectNotifications);
  const statusMessage = useSelector(selectStatusMessage);
  const cursorPosition = useSelector(selectCursorPosition);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div className="bg-background text-text dark:bg-background dark:text-text px-4 py-1 flex justify-between items-center text-xs">
      <div className="flex items-center space-x-4 cursor-pointer">
        <Tippy content="Line / Column">
          <span title="Line/Column">{`Ln ${cursorPosition.line}, Col ${cursorPosition.column}`}</span>
        </Tippy>
        <Tippy content="Shows currently used language intellisense">
          <span>{language.toUpperCase()}</span>
        </Tippy>
        <Tippy content="Shows current mode">
          <span>{`${mode.toUpperCase()} Mode`}</span>
        </Tippy>
        <Tippy content="Shows status">
          <span>{statusMessage}</span>
        </Tippy>
        <Tippy
          content={
            <div className="text-center text-xs">
              Online / Offline
              <br />
              <span>Shows your internet connectivity status</span>
            </div>
          }
          className="bg-rose-500 text-white rounded-md shadow-2xl"
          arrow={true}
          placement="top"
          animation="fade"
          inertia={true}
          theme="custom"
          duration={[500, 1000]}
        >
          <span>
            {isOnline ? (
              <p>
                <FiWifi />
              </p>
            ) : (
              <p>
                <FiWifiOff />
              </p>
            )}
          </span>
        </Tippy>
      </div>
      <div className="flex items-center space-x-4">
        <Tippy content="Errors / Warnings" interactive={true}>
          <button className="flex items-center hover:bg-blue-600 dark:hover:bg-blue-800">
            <FaCode className="mr-2" />
            No Problems
          </button>
        </Tippy>
        <Tippy content="Shows errors" interactive={true}>
          <button className="flex items-center hover:bg-blue-600 dark:hover:bg-blue-800">
            <FaBug className="mr-2" />
            No Errors
          </button>
        </Tippy>
        <Tippy content="Shows syncing" interactive={true}>
          <button className="flex items-center hover:bg-blue-600 dark:hover:bg-blue-800">
            <FaSync className="mr-2" />
            Connected
          </button>
        </Tippy>
        <Tippy content="Show all notifications" interactive={true}>
          <button className="flex items-center hover:bg-blue-600 dark:hover:bg-blue-800">
            <FaBell className="mr-2" />
            {notifications} Notifications
          </button>
        </Tippy>
      </div>
    </div>
  );
};

export default StatusBar;

import { Editor } from "@monaco-editor/react";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "./ThemeContext";
import { useDispatch } from "react-redux";
import { setCursorPosition } from "../features/cursorPositionSlice";

const MonacoEditor = ({
  file,
  handleTestbenchFileContentChange,
  handleDesignFileContentChange,
}) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  console.log("first", file);
  const handleSetCursorPosition = (line, column) => {
    dispatch(setCursorPosition({ line, column }));
  };
  

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
    editor.onDidChangeCursorPosition((e) => {
      const position = editor.getPosition();
      handleSetCursorPosition(position.lineNumber, position.column);
    });
  };

  const { darkMode } = useTheme();
  const [theme, setTheme] = useState(darkMode ? "vs-dark" : "vs-light");
  useEffect(() => {
    setTheme(darkMode ? "vs-dark" : "vs-light");
  }, [darkMode]);

  return (
    <div>
      {" "}
      <Editor
        height="calc(100vh - 200px)" // Editor height based on viewport height.
        language={file.language} // Programming language for syntax highlighting.
        value={file.content} // Content of the editor.
        onChange={(newContent) => {
          // Check if the functions exist before calling
          if (handleTestbenchFileContentChange) {
            handleTestbenchFileContentChange(newContent);
          }
          if (handleDesignFileContentChange) {
            handleDesignFileContentChange(newContent);
          }
        }} // Function triggered on content change.
        onMount={handleEditorDidMount} // Function triggered on mount for initialization.
        options={{
          // Appearance & Layout Options
          minimap: { enabled: false }, // Shows/hides the minimap.
          lineNumbers: "on", // Shows line numbers. Options: "off", "on", "relative", "interval".
          roundedSelection: false, // Makes selection rounded instead of sharp-edged.
          scrollBeyondLastLine: true, // Scroll beyond the last line.
          readOnly: false, // Set to true if editor is read-only.
          theme: theme, // Theme for the editor (vs-dark, vs-light).
          renderLineHighlight: "gutter", // Options: "none", "gutter", "line", "all" (controls line highlight behavior).
          wordWrap: "off", // Wraps long lines. Options: "on", "off", "wordWrapColumn", "bounded".
          wordWrapColumn: 80, // Column to wrap text at when wordWrap is set to "wordWrapColumn".
          wrappingIndent: "none", // Controls the indentation of wrapped lines. Options: "none", "same", "indent", "deepIndent".

          // Scroll Options
          mouseWheelScrollSensitivity: 1, // Sensitivity of mouse wheel scrolling.
          scrollBeyondLastColumn: 5, // Allows horizontal scrolling beyond the last column.
          smoothScrolling: true, // Enables smooth scrolling for better experience.
          fastScrollSensitivity: 5, // Increases scrolling speed when holding `Alt`.

          // Cursor & Selection Options
          cursorStyle: "line", // Style of the cursor. Options: "line", "block", "underline".
          cursorWidth: 2, // Width of the cursor when using "line" style.
          cursorBlinking: "smooth", // Cursor blinking animation. Options: "blink", "smooth", "phase", "expand", "solid".
          cursorSmoothCaretAnimation: true, // Smooth caret animation when moving the cursor.
          selectionClipboard: true, // Enable selection to be copied into the clipboard.
          selectionHighlight: true, // Highlights all matches of the selection.
          matchBrackets: "always", // Controls if matching brackets are highlighted. Options: "never", "near", "always".

          // Text Options
          fontSize: 14, // Font size in pixels.
          fontFamily: '"Fira Code", Consolas, "Courier New", monospace', // Font family for the editor.
          fontWeight: "normal", // Font weight. Can be "normal", "bold", or specific number values.
          fontLigatures: true, // Enables font ligatures.
          lineHeight: 18, // Line height in pixels.
          letterSpacing: 0.5, // Spacing between letters.
          padding: { top: 16, bottom: 16 }, // Padding around editor content.

          // Brackets & Indentation
          autoClosingBrackets: "always", // Automatically close brackets. Options: "always", "languageDefined", "beforeWhitespace", "never".
          autoIndent: "full", // Controls the auto-indent behavior. Options: "none", "keep", "brackets", "advanced", "full".
          folding: true, // Enables code folding in the editor.
          foldingStrategy: "auto", // Folding strategy: "auto" or "indentation".
          foldingHighlight: true, // Highlights folded sections.
          bracketPairColorization: { enabled: true }, // Colorizes matching brackets.

          // Find & Replace Options
          find: {
            addExtraSpaceOnTop: true, // Adds extra space at the top when find widget is displayed.
            autoFindInSelection: "always", // Auto selects text while searching.
            seedSearchStringFromSelection: true, // Uses the current selection as the initial search string.
          },

          // Editor Behavior
          formatOnPaste: true, // Automatically format pasted code.
          formatOnType: true, // Automatically format the code while typing.
          automaticLayout: true, // Automatically adjusts the layout based on the container's size.
          dragAndDrop: true, // Enables drag and drop of selected text.
          copyWithSyntaxHighlighting: true, // Copies code with syntax highlighting into the clipboard.
          codeLens: true, // Enables CodeLens (inline information in the editor, such as references).
          suggestOnTriggerCharacters: true, // Enables suggestions on trigger characters (e.g., after `.` in JavaScript).
          tabCompletion: "on", // Enables tab completion. Options: "on", "off", "onlySnippets".
          tabSize: 4, // Number of spaces a tab equals.
          insertSpaces: true, // Insert spaces when pressing tab.
          suggest: {
            // Suggestion options for code completion.
            showKeywords: true, // Show keyword suggestions.
            showSnippets: true, // Show snippets suggestions.
            showClasses: true, // Show class suggestions.
          },

          // Scrollbars
          scrollbar: {
            vertical: "auto", // Vertical scrollbar visibility. Options: "auto", "visible", "hidden".
            horizontal: "auto", // Horizontal scrollbar visibility. Options: "auto", "visible", "hidden".
            useShadows: true, // Shadow effects around the scrollbar.
            verticalScrollbarSize: 10, // Width of the vertical scrollbar in pixels.
            horizontalScrollbarSize: 10, // Height of the horizontal scrollbar in pixels.
          },

          // Performance & Error Handling
          quickSuggestions: {
            other: true,
            comments: false,
            strings: false,
          }, // Enables inline suggestions in comments, strings, etc.
          suggestSelection: "first", // Selects the first suggestion in the list automatically. Options: "first", "recentlyUsed", "recentlyUsedByPrefix".
          acceptSuggestionOnEnter: "on", // Accepts a suggestion with `Enter`. Options: "on", "smart", "off".
          lightbulb: { enabled: true }, // Enables lightbulb feature for quick-fix suggestions.
          hover: { enabled: true }, // Displays hover tooltips with information on symbols.
          mouseWheelZoom: true, // Enables zoom in/out with mouse wheel and `Ctrl`.
          inlayHints: { enabled: true }, // Displays inline hints for parameter names, types, etc.

          // Diagnostics & Linting
          errorLens: { enabled: true }, // Enables highlighting of errors in the code.
          renderValidationDecorations: "on", // Displays errors, warnings, and info as underlines or in the margin. Options: "on", "off".
          disableLayerHinting: false, // Disable layer hinting (used for performance improvements).
          disableMonospaceOptimizations: false, // Disable optimizations for monospace fonts (may affect rendering).

          // Rendering
          renderWhitespace: "none", // Controls rendering of whitespace characters. Options: "none", "boundary", "selection", "all".
          renderControlCharacters: false, // Shows control characters like null or end-of-text markers.
          renderIndentGuides: true, // Renders indentation guides (dashed lines showing indentation levels).
          highlightActiveIndentGuide: true, // Highlights the active indentation guide when the cursor is in the line.
          renderLineHighlightOnlyWhenFocus: false, // Only highlight the active line when the editor has focus.
          overviewRulerBorder: false, // Hides the border around the overview ruler (used for scroll position markers).
          hideCursorInOverviewRuler: true, // Hides the cursor marker in the overview ruler.
          glyphMargin: false, // Shows an additional margin on the left for icons like breakpoints, error markers, etc.

          // Decorations
          stickyTabStops: true, // Sticky tabs ensure the cursor snaps to spaces on tab stops when navigating the lines.
          inlineHints: true, // Enables inline hints in code.
          foldingImportsByDefault: true, // Automatically folds import sections by default.

          // Accessibility
          accessibilitySupport: "on", // Enables full support for screen readers. Options: "auto", "on", "off".
          ariaLabel: "Editor content", // Accessible label for screen readers.

          // Mouse & Keyboard Interaction
          links: true, // Detects and renders links inside the code.
          multiCursorModifier: "alt", // Key modifier for adding multiple cursors. Options: "ctrlCmd", "alt".
          multiCursorMergeOverlapping: true, // Merges multiple cursors when they overlap.
          columnSelection: false, // Enables column-based selection when using the mouse and keyboard.
        }}
      />
    </div>
  );
};

export default MonacoEditor;

import React from "react";
import { FaPlayCircle } from "react-icons/fa";
import Logs from "./Logs";
import EDAlogs from "./EDAlogs";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { clearEDALogs } from "../features/edaLogsSlice";
import { clearLogs } from "../features/logsSlice";

const BothLogs = ({ terminalOutput }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="flex">
        <div className="w-1/2 flex items-center justify-between text-primary px-2 py-[1px]">
          <div className="flex items-center">
            <FaPlayCircle className="mr-2" />
            <span className="font-bold">Logs</span>
          </div>
          <div>
            <button
              className="flex items-center border text-text hover:text-white bg-red-400 hover:bg-red-500 border-text px-1 m-[1px] rounded-sm"
              onClick={() => dispatch(clearLogs())}
              title="Clear logs"
            >
              <MdDelete className="mr-2" />
              <span className="font-bold">Clear Logs</span>
            </button>
          </div>
        </div>
        <div className="w-[2px] bg-secondary" />
        <div className="w-1/2 flex items-center justify-between text-primary px-2 py-[1px]">
          <div className="flex items-center">
            <FaPlayCircle className="mr-2" />
            <span className="font-bold">EDA Logs</span>
          </div>
          <div>
            <button
              className="flex items-center border text-text hover:text-white bg-red-400 hover:bg-red-500 border-text px-1 m-[1px] rounded-sm"
              onClick={() => dispatch(clearEDALogs())}
              title="Clear logs"
            >
              <MdDelete className="mr-2" />
              <span className="font-bold">Clear EDA Logs</span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-full h-full">
        <Logs />
        <div className="w-[2px] bg-secondary" />
        <div className="w-1/2 bg-background text-accent p-4 font-mono text-xs pb-16 overflow-auto custom-scrollbar-logs">
          <EDAlogs />
        </div>
      </div>
    </>
  );
};

export default BothLogs;
